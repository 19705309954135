import React from 'react'
import { Link } from 'react-router-dom'

const routes = [
  {
    to: '/productos/todos',
    name: 'Todos los productos'
  },
  {
    to: '/productos/categorias',
    name: 'Categorias'
  },
]

export const LinksLayout = ({ children, title, links = routes }) => {
  return (
    <div>
      <h2 className='overviewPage__title'>{title}</h2>
      <div className='layout__links mb-20'>
        {
          links.map(link => (
            <Link to={link.to}>
              <div className='sidebar__link'>
                <span>{link.name}</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </div>
            </Link>
          ))
        }
      </div>
      {children}
    </div>
  )
}
