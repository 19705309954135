import { Input, Table, Tag } from 'antd'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { api } from '../../api/api'
import { Image } from '../../components/Image'
import { LinksLayout } from '../../components/LinksLayout'
import { VerticalTable } from '../../components/VerticalTable'
import { Product } from '../../interfaces/Product'

export const AllProducts = () => {

  const [products, setProducts] = useState([])


  useEffect(() => {
    api.get(`/api/products/`).then(({ data }) => {
      setProducts(data.products)
    })
  }, [])

  const headers = [
    {
      title: 'Imagen',
      dataIndex: 'image',
      key: 'image',
      render: (text: string, record: Product) => <Image
        style={{ width: 50 }}
        src={record.images[0]} />
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Código',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Precio',
      dataIndex: 'price',
      key: 'price',
      render: (text: string, record: Product) => record.price ? <>{`${record.price} MXN`}</> : 'N/A',
      // sorter: (a: Product, b: Product) => a.price - b.price,
    },
    {
      title: 'Categoria(s)',
      dataIndex: 'category',
      key: 'category',
      render: (text: string, record: Product) => {
        return (
          record.category.map(category => (
            <span>{category.name}</span>
          ))
        )
      }
      // render: (text: string, record: Product) => record.category?.name,
      /* filters: categories?.map(category => ({
        text: category?.name,
        value: category?.code
      })),
      onFilter: (value, record) => record.category?.code.includes(value), */
    },
    {
      title: 'Publicado',
      dataIndex: 'published',
      key: 'published',
      render: (text: string, record: Product) => <Tag color={record.published ? 'green' : 'cyan'}>{record.published ? 'Publicado' : 'No Publicado'}</Tag>
    },
    {
      title: 'Detalles',
      dataIndex: 'detalles',
      key: 'detalles',
      render: (text: string, record: Product) => <Link to={`/productos/${record.code}`} className='btn btn-black'>Ver</Link>
    },
  ]

  const handleSearch = (e: any) => {
    /*  const searchTerm = e.target.value.toLowerCase()
     setProducts(tempProducts.filter((product) => product.name?.toLowerCase().includes(searchTerm) || product.category?.name?.toLowerCase().includes(searchTerm))) */
  }
  return (
    <LinksLayout title='Productos'>
      <div className='overviewPage'>
        <div className="overviewPage__content">
          <div className='flex justify-between align-center mb-20'>
            <h2>Todos los productos</h2>
            <Link to='/productos/agregar-producto' className='btn btn-black'>Agregar producto</Link>
          </div>
          <div className="mb-20">
            <Input onChange={handleSearch} placeholder='Buscar productos por nombre o categoria' />
          </div>
          <div className='mobile-table'>
            <VerticalTable data={products} headers={headers} />
          </div>
          <div className='web-table'>
            <Table size='small' dataSource={products} columns={headers} />
          </div>
        </div>
      </div>
    </LinksLayout>

  )
}
