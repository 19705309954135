import { Button, message } from 'antd'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { ArrowLeftOutlined } from "@ant-design/icons";
import Input from '../../components/Input'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'
import { api } from '../../api/api';

export const AddDiscount = () => {
  const history = useHistory()
  const [saving, setSaving] = useState(false)
  const { register, handleSubmit, control, formState: { errors } } = useForm();


  const onSubmit = async (values: any) => {
    const discount = {
      ...values,
      type: values.type.value,
      expiryDate: new Date()
    }
    try {
      await api.post('/api/discounts', discount)
      message.success('Descuento creado')
      history.replace('/descuentos')
    } catch (error: any) {
      message.error(error.response.data.message)
    }
  }

  return (
    <div className='detailPage'>
      <div className="flex align-center justify-between mb-20">
        <div className="flex">
          <Button onClick={() => { history.goBack() }} className='mr-10' shape="circle" icon={<ArrowLeftOutlined />} />
          <h3>Agregar descuento</h3>
        </div>

        <div className="flex">
          <button disabled={saving} onClick={handleSubmit(onSubmit)} className='btn btn-black'>{saving ? 'Guardando...' : 'Guardar'}</button>
        </div>

      </div>
      <div className='card'>
        <div className="group">
          <Input
            required
            register={register}
            placeholder='Nombre de descuento'
            name='name'
            label='Nombre de descuento'
            errors={errors}
          />
        </div>
        <div className="group">
          <span>Tipo de descuento</span>
          <Controller
            rules={{
              required: true,
            }}
            name="type"
            control={control}
            render={({ field: { onChange, onBlur, value } }) =>
              <Select
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                placeholder='Tipo de descuento'
                isSearchable={true}
                options={[
                  {
                    label: 'Porcentaje',
                    value: 'percentage'
                  },
                  {
                    label: 'Monto fijo',
                    value: 'fixed'
                  },
                ]}
              />}
          />
          {errors.type && <span className='error'>Campo requerido</span>}
        </div>
        <div className="group">
          <Input
            type='number'
            required
            register={register}
            placeholder='Valor del descuento'
            name='value'
            label='Valor del descuento'
            errors={errors}
          />
        </div>
        <div className="group">
          <input {...register('active')} type="checkbox" name="active" id="active" />
          <label htmlFor="active">Activo</label>
        </div>
      </div>
    </div>
  )
}
