import { message, Popconfirm, Table, Tag } from 'antd'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { api } from '../../api/api'
import { VerticalTable } from '../../components/VerticalTable'
import { AuthContext } from '../../context/AuthProvider'
import { Discount } from '../../interfaces/Discount'


export const Discounts = () => {

  const [discounts, setDiscounts] = useState([])
  const { user } = useContext(AuthContext)
  useEffect(() => {
    api.get(`/api/discounts`,
      {
        headers: {
          'Content-type': 'application/json',
          'x-token': user.token
        }
      }
    ).then(({ data }) => {
      setDiscounts(data.discounts)
    })
  }, [user.token])

  const headers = [
    {
      title: 'Nombre de codigo',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Tipo de descuento',
      dataIndex: 'type',
      key: 'type',
      render: (text: string) => text === 'percentage' ? 'Porcentaje' : 'Monto fijo'
    },
    {
      title: 'Activo',
      dataIndex: 'active',
      key: 'active',
      render: (text: string, record: Discount) => <Tag color={record.active ? 'rgb(135, 208, 104)' : 'rgb(155 155 155)'}>{record.active ? 'Activo' : 'Vencido'}</Tag>
    },
    {
      title: 'Valor del descuento',
      dataIndex: 'total',
      key: 'total',
      render: (text: string, record: Discount) => record.type === 'percentage' ? `${record.value} %` : `$${record.value} MXN`
    },
    {
      title: 'Detalles',
      dataIndex: 'details',
      key: 'details',
      render: (text: string, record: Discount) => <Link to={`/descuentos/${record.id}`} className='btn btn-small btn-black'>Ver</Link>
    },
  ]

  return (
    <div className='overviewPage'>
      <h2 className='overviewPage__title'>Descuentos</h2>
      <div className='overviewPage__content'>
        <div className='flex justify-between align-center mb-20'>
          <h2>Todos los descuentos</h2>
          <Link to='/descuentos/agregar-descuento' className='btn btn-black'>Agregar descuento</Link>
        </div>
        <div className='mobile-table'>
          <VerticalTable data={discounts} headers={headers} />
        </div>
        <div className='web-table'>
          <Table dataSource={discounts} size='small' columns={headers} />
        </div>
      </div>
    </div>
  )
}
