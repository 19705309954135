import { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import { Button, message, Tag } from 'antd'
import { LoadingOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { AuthContext } from '../../context/AuthProvider'
import { Order as IOrder } from '../../interfaces/Order'
import { api } from '../../api/api'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'
import Input from '../../components/Input';


export const Order = () => {
  const { number } = useParams<any>()
  const [order, setOrder] = useState({} as IOrder)
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const [saving, setSaving] = useState(false)
  const [editing, setEditing] = useState(false)
  const { user } = useContext(AuthContext)
  const { register, handleSubmit, control, formState: { errors } } = useForm();
  const [drivers, setDrivers] = useState<any>([])

  useEffect(() => {
    api.get(`/api/orders/${number}`,
      {
        headers: {
          'Content-type': 'application/json',
          'x-token': user.token
        }
      }
    ).then(({ data }) => {
      setOrder(data.order)
      setLoading(false)
    })
  }, [number, user.token])



  useEffect(() => {
    api.get(`/api/delivery/`).then(({ data }) => {
      setDrivers(data)
    })
  }, [])

  const onSubmit = async (data: any) => {
    try {
      await api.put(`/api/orders/${order.number}`, {
        ...data,
        shippingType: data.shippingType.value,
        products: data.products?.map((option: any) => option.value),
        channel: data.channel.value,
        status: data.status.value,
        driver: data.driver.value
      })
      message.success('Pedido actualizado')
      setEditing(false)
    } catch (error: any) {
      console.log(error);
      message.error(error.response.data.message)
    }
  }
  return (

    <div className='detailPage'>
      <h3>Detalles del pedido</h3>
      {
        loading ?
          <div className="flex align-center justify-between mb-20">
            <h3>Cargando...</h3>
            <LoadingOutlined style={{ fontSize: 30 }} />
          </div>
          :
          <>
            <div className="flex align-center justify-between mb-20">
              <div className="flex">
                <Button onClick={() => { history.goBack() }} className='mr-10' shape="circle" icon={<ArrowLeftOutlined />} />
                <h3>Numero de pedido: {order.number}</h3>
              </div>
              <div className='flex'>
                {
                  !editing && <button className='btn btn-black' onClick={() => { setEditing(true) }}>Editar</button>
                }
                {
                  editing &&
                  <div className="flex">
                    <button disabled={saving} onClick={() => { setEditing(false) }} className='btn btn-outlined mr-10'>Cancelar</button>
                    <button disabled={saving} onClick={handleSubmit(onSubmit)} className='btn btn-black'>{saving ? 'Guardando...' : 'Guardar'}</button>
                  </div>
                }
              </div>
            </div>
            {
              !editing ?
                <>
                  <div className='order__wrapper'>
                    <div className="card">
                      <div className='flex justify-between'>
                        <div className='card__item'>
                          <h4>Numero de pedido</h4>
                          <span>{order.number}</span>
                        </div>
                        <div className='card__item'>
                          <Tag className='mb-20' color={order.status === 'Nuevo' ? '#2db7f5' : order.status === 'Enviado' ? '#faad14' : order.status === 'Entregado' ? '#87d068' : '#d9363e'}>{order.status}</Tag>
                        </div>
                      </div>
                      <div className='card__item'>
                        <h4>Metodo de pago</h4>
                        <span>{order.paymentMethod}</span>
                      </div>
                      <div className='card__item'>
                        <h4>Fecha de pedido</h4>
                        <span>{moment(order.createdDate).format('lll')}</span>
                      </div>
                      <div className='card__item'>
                        <h4>Tipo de envío</h4>
                        <span>{order.shippingType}</span>
                      </div>
                      <div className='card__item'>
                        <h4>Repartidor asignado</h4>
                        <span>{order.driver}</span>
                      </div>
                      <div className='card__item'>
                        <h4>Dirección de entrega</h4>
                        <span>{order.shippingAddress.street}, {order.shippingAddress.colonia}, {order.shippingAddress.postalCode}, {order.shippingAddress.city}, {order.shippingAddress.state}</span>
                      </div>

                      <div className="card__item">
                        <h4>Productos pedidos</h4>
                        {
                          order.cart ? order.cart.products.map(product => (
                            <div>
                              {product.name}
                            </div>
                          )) : order.products.map(product => (
                            <div>
                              {product.name}
                            </div>
                          ))
                        }
                      </div>
                      <div className='card__item'>
                        <h4>Informacion de pago</h4>

                        <div className="flex justify-between">
                          <span>
                            Subtotal de articulos:
                          </span>
                          <span>
                            ${order.subTotal} MXN
                          </span>
                        </div>

                        <div className="flex justify-between">
                          <span>
                            Envio:
                          </span>
                          <span>
                            ${order.shippingFee} MXN
                          </span>
                        </div>
                        {
                          order.cart && order.cart.discount &&
                          <>
                            <div className="flex justify-between">
                              <span>
                                Descuentos:
                              </span>
                              <span>- $ {
                                order.cart.discount.type === 'percentage' ? order.subTotal * (order.cart.discount.value / 100) : 0
                              } MXN</span>
                            </div>
                            <div className="flex justify-between">
                              <span>
                                Código:
                              </span>
                              <span>{order.cart.discount.name}</span>
                            </div>
                          </>
                        }
                        <div className="flex justify-between">
                          <span>
                            <b>Total pagado:</b>
                          </span>
                          <span>
                            <b>${order.total} MXN</b>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='flex column'>
                      <div className='order__customer card'>
                        <div className='card__item'>
                          <h4>Nombre de cliente</h4>
                          <span>{order.customer}</span>
                        </div>
                        <div className='card__item'>
                          <h4>Datos de contacto</h4>
                          <span>{order.phone}</span>
                          <span>{order.email}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </> :

                <div className="card">
                  <div className="group">
                    <span>Status de pedido</span>
                    <Controller
                      name="status"
                      control={control}
                      defaultValue={{
                        label: order.status,
                        value: order.status
                      }}
                      rules={{
                        required: true
                      }}
                      render={({ field: { onChange, onBlur, value } }) =>
                        <Select
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          placeholder='Tipo de envío'
                          isSearchable={true}
                          options={[
                            {
                              label: 'Nuevo',
                              value: 'Nuevo'
                            },
                            {
                              label: 'Enviado',
                              value: 'Enviado'
                            },
                            {
                              label: 'Cancelado',
                              value: 'Cancelado'
                            },
                            {
                              label: 'Entregado',
                              value: 'Entregado'
                            },
                          ]}
                        />}
                    />
                    {errors.status && <span className='error'>Campo requerido</span>}
                  </div>
                  <h4>
                    Datos personales y de entrega
                  </h4>
                  <div className="group">
                    <Input
                      name='customer'
                      defaultValue={order.customer}
                      register={register}
                      placeholder='Nombre y apellido'
                      required
                      label='Nombre y apellido'
                      errors={errors}
                    />
                  </div>

                  <div className="group">
                    <Input
                      type='email'
                      name='email'
                      defaultValue={order.email}
                      register={register}
                      placeholder='Correo electrónico'
                      required
                      label='Correo electrónico'
                      errors={errors}
                    />
                  </div>

                  <div className="group">
                    <Input
                      name='phone'
                      defaultValue={order.phone}
                      register={register}
                      placeholder='Teléfono de contacto'
                      required
                      label='Teléfono de contacto'
                      errors={errors}
                    />
                  </div>
                  <div className="group">
                    <span>Repartidor asignado</span>
                    <Controller
                      name="driver"
                      control={control}
                      defaultValue={{
                        label: order.driver,
                        value: order.driver
                      }}
                      rules={{
                        required: true
                      }}
                      render={({ field: { onChange, onBlur, value } }) =>
                        <Select
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          placeholder='Repartidor asignado'
                          isSearchable={true}
                          options={drivers.map((driver: any) => ({
                            label: driver.name,
                            value: driver.name
                          }))}
                        />}
                    />
                    {errors.shippingType && <span className='error'>Campo requerido</span>}
                  </div>
                  <h4>Dirección de entrega</h4>
                  <div className="group">
                    <span>Tipo de envío</span>
                    <Controller
                      name="shippingType"
                      control={control}
                      defaultValue={{
                        label: order.shippingType,
                        value: order.shippingType
                      }}
                      rules={{
                        required: true
                      }}
                      render={({ field: { onChange, onBlur, value } }) =>
                        <Select
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          placeholder='Tipo de envío'
                          isSearchable={true}
                          options={[
                            {
                              label: 'Local',
                              value: 'Local'
                            },
                            {
                              label: 'Local express',
                              value: 'Local express'
                            },
                            {
                              label: 'Nacional',
                              value: 'Nacional'
                            },
                          ]}
                        />}
                    />
                    {errors.shippingType && <span className='error'>Campo requerido</span>}
                  </div>

                  <div className="group">
                    <Input
                      defaultValue={order.shippingAddress.street}
                      name='shippingAddress.street'
                      register={register}
                      placeholder='Calle y número'
                      required
                      label='Calle y número'
                      errors={errors}
                    />
                  </div>
                  <div className="group">
                    <Input
                      defaultValue={order.shippingAddress.colonia}
                      name='shippingAddress.colonia'
                      register={register}
                      placeholder='Colonia'
                      required
                      label='Colonia'
                      errors={errors}
                    />
                  </div>
                  <div className="group">
                    <Input
                      defaultValue={order.shippingAddress.postalCode}
                      name='shippingAddress.postalCode'
                      register={register}
                      placeholder='Código postal'
                      required
                      label='Código postal'
                      errors={errors}
                    />
                  </div>
                  <div className="group">
                    <Input
                      defaultValue={order.shippingAddress.state}
                      name='shippingAddress.state'
                      register={register}
                      placeholder='Estado'
                      required
                      label='Estado'
                      errors={errors}
                    />
                  </div>
                  <div className="group">
                    <Input
                      name='shippingAddress.city'
                      defaultValue={order.shippingAddress.city}
                      register={register}
                      placeholder='Municipio'
                      required
                      label='Municipio'
                      errors={errors}
                    />
                  </div>
                  {
                    !order.cart &&
                    <div className="group">
                      <span>Productos vendidos</span>
                      <Controller
                        name="products"
                        control={control}
                        rules={{
                          required: true
                        }}
                        defaultValue={order.products.map((product: any) => (
                          {
                            label: product.name,
                            value: product._id
                          }
                        ))}
                        render={({ field: { onChange, onBlur, value } }) =>
                          <Select
                            isMulti
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            placeholder='Productos vendidos'
                            isSearchable={true}
                          /* options={products.map((product: any) => (
                            {
                              label: product.name,
                              value: product.id
                            }
                          ))} */
                          />}
                      />
                      {errors.products && <span className='error'>Campo requerido</span>}
                    </div>
                  }

                  <div className="group">
                    <span>Canal de venta</span>
                    <Controller
                      name="channel"
                      control={control}
                      rules={{
                        required: true
                      }}
                      defaultValue={{
                        label: order.channel,
                        value: order.channel
                      }}
                      render={({ field: { onChange, onBlur, value } }) =>
                        <Select
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          placeholder='Canal de venta'
                          isSearchable={true}
                          options={[
                            {
                              label: 'Facebook',
                              value: 'Facebook'
                            },
                            {
                              label: 'Instagram',
                              value: 'Instagram'
                            },
                            {
                              label: 'WhatsApp',
                              value: 'WhatsApp'
                            },
                          ]}
                        />}
                    />
                    {errors.channel && <span className='error'>Campo requerido</span>}
                  </div>

                  <div className="group">
                    <Input
                      defaultValue={order.subTotal}
                      type='number'
                      name='subTotal'
                      register={register}
                      placeholder='Subtotal'
                      required
                      label='Subtotal'
                      errors={errors}
                    />
                  </div>
                  <div className="group">
                    <Input
                      defaultValue={order.shippingFee}
                      type='number'
                      name='shippingFee'
                      register={register}
                      placeholder='Envío'
                      required
                      label='Envío'
                      errors={errors}
                    />
                  </div>
                  <div className="group">
                    <Input
                      defaultValue={order.total}
                      type='number'
                      name='total'
                      register={register}
                      placeholder='Total'
                      required
                      label='Total'
                      errors={errors}
                    />
                  </div>
                </div>
            }
          </>
      }
    </div >

  )
}
