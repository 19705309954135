import { Button, message } from 'antd'
import { useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { MinusCircleTwoTone } from '@ant-design/icons'
import { LoadingOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { AuthContext } from '../../../context/AuthProvider'
import { api } from '../../../api/api'
import { Category as ICategory } from '../../../interfaces/Category'
import { useForm } from 'react-hook-form'
import imageCompression from 'browser-image-compression';
import Input from '../../../components/Input'

export const Category = () => {

  const { categoryId } = useParams<any>()
  const [editing, setEditing] = useState(false)
  const imageRef = useRef<any>()
  const [image, setImage] = useState('')
  const [uploading, setUploading] = useState(false)
  const [saving, setSaving] = useState(false)
  const history = useHistory()
  const [category, setCategory] = useState({} as ICategory)
  const [loading, setLoading] = useState(true)
  const { user } = useContext(AuthContext)
  const { register, handleSubmit, formState: { errors } } = useForm();


  useEffect(() => {
    api.get(`/api/categories/${categoryId}`).then(({ data }) => {
      setCategory(data.category)
      setLoading(false)
      setImage(data.category.picture)
    })
  }, [categoryId, saving])

  const handleFileUpload = async (e: any) => {
    const file = e.target.files[0]
    if (!file) {
      return
    }
    setUploading(true)
    const options = {
      maxSizeMB: 6,
      maxWidthOrHeight: 3000,
      useWebWorker: true,
    }
    let compressedBlob
    let compressedFile

    const formData = new FormData()
    try {
      compressedBlob = await imageCompression(file, options);
      compressedFile = new File([compressedBlob], compressedBlob.name)
    } catch (error) {
    }

    formData.append('picture', compressedFile as any)

    try {
      const { data } = await api.post('/api/categories/upload', formData,
        {
          headers: {
            'Content-type': 'application/json',
            'x-token': user.token
          }
        }
      )
      message.success('Imagen cargada')
      setUploading(false)
      setImage(data.Location)
    } catch (error: any) {
      message.error(error.response.data.message)
      setUploading(false)
    }
  }

  const onSubmit = async (data: any) => {
    if (image === '') return message.error('Elige una imagen para la categoria')
    try {
      const update = {
        ...data,
        picture: image
      }
      await api.put(`/api/categories/${category.id}`, update)
      message.success('Categoria actualizada')
    } catch (error: any) {
      message.error(error.response.data.message)
    }

  }

  return (
    <div className='detailPage'>

      {
        loading ? <>
          <div className="flex align-center justify-between mb-20">
            <h3>Cargando...</h3>
            <LoadingOutlined style={{ fontSize: 30 }} />
          </div>
        </> : <>

          <div className="flex align-center justify-between mb-20">
            <div className="flex">
              <Button onClick={() => { history.goBack() }} className='mr-10' shape="circle" icon={<ArrowLeftOutlined />} />
              <h3>{category.name}</h3>
            </div>
            {
              !editing && <button className='btn btn-black' onClick={() => { setEditing(true) }}>Editar</button>
            }
            {
              editing &&
              <div className="flex">
                <button disabled={saving} onClick={() => { setEditing(false) }} className='btn btn-outlined mr-10'>Cancelar</button>
                <button disabled={saving || uploading} onClick={handleSubmit(onSubmit)} className='btn btn-black'>{saving ? 'Guardando...' : uploading ? 'Subiendo imagen...' : 'Guardar'}</button>
              </div>
            }
          </div>

          <div className="card">
            {
              editing ?
                <div>
                  <div className="group">
                    <Input
                      defaultValue={category.name}
                      register={register}
                      placeholder='Nombre'
                      name='name'
                      errors={errors}
                      label='Nombre de categoria'
                      required
                    />
                  </div>
                  <div className="group">
                    <Input
                      defaultValue={category.description}
                      register={register}
                      placeholder='Description'
                      name='description'
                      errors={errors}
                      label='Descripcion de categoría'
                      required
                    />
                  </div>
                  <div className="group">
                    <input defaultChecked={category.active} {...register('active')} type="checkbox" name="active" id="active" />
                    <label htmlFor="active">Activa</label>
                  </div>
                  <div>

                    <input
                      onChange={handleFileUpload}
                      ref={imageRef}
                      style={{ display: 'none' }}
                      type='file'
                    />
                    <div style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>
                      {
                        image && <button
                          type='button' className='imagePreview__img'>
                          <img style={{ width: 150 }} src={image} alt='' />
                          <Button
                            htmlType='button'
                            shape='circle'
                            icon={<MinusCircleTwoTone twoToneColor='red' />}
                            type='link'
                            onClick={() => {
                              setImage('')
                            }}
                          />
                        </button>
                      }
                      <div onClick={() => { imageRef.current.click() }} style={
                        {
                          width: 150,
                          height: 150,
                          border: '2px dashed #cdcdcd',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textAlign: 'center',
                          fontSize: 14,
                          cursor: 'pointer',
                          marginLeft: image ? 20 : 0
                        }
                      }>
                        <span>
                          {image ? 'Cambiar' : 'Elegir'} imagen
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                :
                <>
                  <div className="card__item">
                    <h4>Nombre</h4>
                    <span>{category.name}</span>
                  </div>
                  <div className="card__item">
                    <h4>Código</h4>
                    <span>{category.code}</span>
                  </div>
                  <div className="card__item">
                    <h4>Descripción</h4>
                    <span>{category.description}</span>
                  </div>
                  <div className="card__item">
                    <h4>Activa</h4>
                    <span>{category.active ? 'Si' : 'No'}</span>
                  </div>
                  <div className="card__item">
                    <h4>Imagen en home</h4>
                    <img style={{ height: 150 }} src={category.picture} alt="" />
                  </div>
                </>
            }
          </div>
        </>
      }

    </div>
  )
}
