import { Button, message } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { LoadingOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import { AuthContext } from '../../context/AuthProvider'
import { api } from '../../api/api'
import { Product as IProduct } from '../../interfaces/Product'
import Input from '../../components/Input'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'
import { Category } from '../../interfaces/Category'
import { Upload } from '../../components/Upload'
import TextArea from '../../components/TextArea'

export const Product = () => {
  const { productId } = useParams<any>()
  const [editing, setEditing] = useState(false)
  const [images, setImages] = useState<string[]>([])
  const [saving, setSaving] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [categories, setCategories] = useState<Category[]>([])
  const [product, setProduct] = useState({} as IProduct)
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const { user } = useContext(AuthContext)
  const { register, handleSubmit, control, formState: { errors } } = useForm();
  const [hasDiscount, setHasDiscount] = useState(false);
  const subscribeToHasDiscount = register('hasDiscount')
  const [specList, setSpecList] = useState<string[]>([])
  const [currentEditing, setcurrentEditing] = useState<number | null>(null);
  const [editingSpec, setEditingSpec] = useState('');
  const [specValue, setSpecValue] = useState('');

  useEffect(() => {
    api.get(`/api/categories/`).then(({ data }) => {
      setCategories(data.categories)
    })
    api.get(`/api/products/${productId}`).then(({ data }) => {
      setProduct(data.product)
      setLoading(false)
      setImages(data.product.images)
      setHasDiscount(data.product.hasDiscount)
      setSpecList(data.product.specs)
    })
  }, [productId, saving, user.token])

  const onSubmit = async (data: any) => {
    if (images.length === 0) return message.error('Elige al menos 1 imagen')
    try {
      const update = {
        ...data,
        category: data.category.map((category: any) => category.value),
        images,
        discountValue: hasDiscount ? data.discountValue : 0,
        specs: specList
      }
      await api.put(`/api/products/${product.id}`, update)
      message.success('Producto actualizado')
      setEditing(false)
      setProduct({
        ...product,
        ...data,
        discountValue: hasDiscount ? data.discountValue : 0,
        specs: specList
      })
    } catch (error: any) {
      message.error(error.response.data.message)
    }
  }

  return (
    <div className='detailPage'>
      {
        loading ?
          <div className="flex align-center justify-between mb-20">
            <h3>Cargando...</h3>
            <LoadingOutlined style={{ fontSize: 30 }} />
          </div>
          :
          <>
            <div className="flex align-center justify-between mb-20">
              <div className="flex">
                <Button onClick={() => { history.goBack() }} className='mr-10' shape="circle" icon={<ArrowLeftOutlined />} />
                <h3>{product.name}</h3>
              </div>
              {
                !editing && <button className='btn btn-black' onClick={() => { setEditing(true) }}>Editar</button>
              }
              {
                editing &&
                <div className="flex">
                  <button disabled={saving} onClick={() => { setEditing(false) }} className='btn btn-outlined mr-10'>Cancelar</button>
                  <button disabled={saving || uploading} onClick={handleSubmit(onSubmit)} className='btn btn-black'>{saving ? 'Guardando...' : 'Guardar'}</button>
                </div>
              }
            </div>
            <div className="card">
              {
                editing ?
                  <div>
                    <div className="group">
                      <Input
                        register={register}
                        defaultValue={product.name}
                        placeholder='Nombre'
                        name='name'
                        errors={errors}
                        label='Nombre del producto'
                        required
                      />
                    </div>
                    <div className="group">
                      <TextArea
                        register={register}
                        defaultValue={product.description}
                        placeholder='Descripción'
                        name='description'
                        errors={errors}
                        label='Descripción del producto'
                        required
                      />
                    </div>
                    <div className="group">
                      <Input
                        register={register}
                        defaultValue={product.price}
                        placeholder='Precio'
                        name='price'
                        errors={errors}
                        label='Precio'
                        required
                      />
                    </div>
                    <div className="group">
                      <Input
                        register={register}
                        defaultValue={product.type}
                        placeholder='Tipo de producto'
                        name='type'
                        errors={errors}
                        label='Tipo de producto'
                        required
                      />
                    </div>
                    <div className="group">
                      <Input
                        register={register}
                        placeholder='Medidas'
                        defaultValue={product.size}
                        name='size'
                        errors={errors}
                        label='Medidas'
                      />
                    </div>
                    <div className="group">
                      <Input
                        register={register}
                        defaultValue={product.material}
                        placeholder='Material'
                        name='material'
                        errors={errors}
                        label='Material'
                      />
                    </div>
                    <div className="group">
                      <TextArea
                        register={register}
                        defaultValue={product.howTo}
                        placeholder='Como usar'
                        name='howTo'
                        errors={errors}
                        label='Como usar'
                      />
                    </div>
                    <div className="group">
                      <TextArea
                        register={register}
                        defaultValue={product.maintenance}
                        placeholder='Mantenimiento'
                        name='maintenance'
                        errors={errors}
                        label='Mantenimiento'
                      />
                    </div>
                    <div className="group">
                      <span>Categoría(s)</span>
                      <Controller
                        name="category"
                        defaultValue={product.category.map(item => ({
                          value: item._id,
                          label: item.name
                        }))}
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) =>
                          <Select
                            isMulti
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            placeholder='Categoría(s)'
                            isSearchable={true}
                            options={categories.map(item => ({
                              value: item.id,
                              label: item.name
                            }))}
                          />}
                      />
                      {errors.category && <span className='error'>Campo requerido</span>}
                    </div>
                    <div className="group">
                      <span>Iconos</span>
                      <Controller
                        name="icons"
                        control={control}
                        defaultValue={product.icons}
                        render={({ field: { onChange, onBlur, value } }) =>
                          <Select
                            isMulti
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            placeholder='Iconos'
                            isSearchable={true}
                            options={[
                              {
                                label: 'Anal',
                                value: 'anal'
                              },
                              {
                                label: 'A prueba de agua',
                                value: 'prueba-de-agua'
                              },
                              {
                                label: 'Recargable',
                                value: 'recargable'
                              },
                              {
                                label: 'Silencioso',
                                value: 'silencioso'
                              },
                              {
                                label: 'Tacto suave',
                                value: 'tacto-suave'
                              },
                              {
                                label: 'Vibrador',
                                value: 'vibrador'
                              },
                            ]}
                          />}
                      />
                      {errors.category && <span className='error'>Campo requerido</span>}
                    </div>
                    <div className="specs group">
                      <label htmlFor="">Especificaciones</label>
                      <div>
                        <input
                          value={specValue}
                          placeholder='Escribe aqui y da click en +'
                          onChange={(e) => {
                            setSpecValue(e.target.value)
                          }}
                          type="text" className="input" />
                        <div
                          onClick={() => {
                            if (specValue === '') return
                            setSpecList([...specList, specValue])
                            setSpecValue('')
                          }}
                          className='icon'>
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                          </svg>
                        </div>
                      </div>
                      <div className="specList">
                        <h4>Especificaciones añadidas</h4>
                        {
                          specList.map((item, index) => (
                            <div key={item}>
                              <div className="left">
                                {
                                  index + 1 === currentEditing ?
                                    <input
                                      defaultValue={item}
                                      onChange={(e) => {
                                        setEditingSpec(e.target.value)
                                      }}
                                      className='input'
                                      type="text"
                                    /> : <span
                                    >{item}
                                    </span>
                                }
                              </div>
                              <div className="right">
                                {
                                  !currentEditing &&
                                  <svg
                                    onClick={() => {
                                      setcurrentEditing(index + 1)
                                    }}
                                    xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                  </svg>
                                }
                                {
                                  currentEditing === index + 1 &&
                                  <svg
                                    onClick={() => {
                                      setcurrentEditing(null)
                                      const specs = [...specList]
                                      specs[currentEditing - 1] = editingSpec
                                      setSpecList(specs)
                                    }}
                                    xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                  </svg>
                                }
                                <svg
                                  onClick={() => {
                                    setSpecList(specList.filter(spec => spec !== item))
                                  }}
                                  xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                    <div className="group">
                      <input
                        defaultChecked={product.soldOut}
                        {...register('soldOut')}
                        type="checkbox"
                        name="soldOut"
                        id="soldOut"
                      />
                      <label htmlFor="soldOut">Marcar como agotado</label>
                    </div>
                    <div className="group">
                      <input
                        defaultChecked={product.hasDiscount}
                        {...subscribeToHasDiscount}
                        onChange={(e) => {
                          setHasDiscount(e.target.checked)
                        }}
                        type="checkbox"
                        name="hasDiscount"
                        id="hasDiscount"
                      />
                      <label htmlFor="hasDiscount">Tiene descuento</label>
                    </div>
                    {
                      hasDiscount &&
                      <div className="group">
                        <Input
                          defaultValue={product.discountValue}
                          required
                          register={register}
                          placeholder='Valor del descuento'
                          name='discountValue'
                          errors={errors}
                          label='Valor del descuento'
                        />
                      </div>
                    }
                    <div className="group">
                      <input
                        defaultChecked={product.published}
                        {...register('published')}
                        type="checkbox"
                        name="published"
                        id="published"
                      />
                      <label htmlFor="published">Publicar</label>
                    </div>
                    <Upload
                      url={`/api/products/upload`}
                      images={images}
                      setImages={setImages}
                      uploading={uploading}
                      setUploading={setUploading}
                    />
                  </div>
                  : <>
                    <div className="card__item">
                      <h4>Nombre</h4>
                      <span>{product.name}</span>
                    </div>
                    <div className="card__item">
                      <h4>Código</h4>
                      <span>{product.code}</span>
                    </div>
                    <div style={{
                      whiteSpace: 'pre-line'
                    }} className="card__item">
                      <h4>Descripcion</h4>
                      <span>{product.description}</span>
                    </div>
                    <div style={{
                      whiteSpace: 'pre-line'
                    }} className="card__item">
                      <h4>Como usar</h4>
                      <span>{product.howTo}</span>
                    </div>
                    <div className="card__item">
                      <h4>Tipo de producto</h4>
                      <span>{product.type}</span>
                    </div>
                    <div className="card__item">
                      <h4>Precio</h4>
                      <span>${product.price} MXN</span>
                    </div>
                    <div className="card__item">
                      <h4>Medidas</h4>
                      <span>{product.size}</span>
                    </div>
                    <div className="card__item">
                      <h4>Material</h4>
                      <span>{product.material}</span>
                    </div>
                    <div style={{
                      whiteSpace: 'pre-line'
                    }} className="card__item">
                      <h4>Mantenimiento</h4>
                      <span>{product.maintenance}</span>
                    </div>
                    <div className="card__item">
                      <h4>Agotado</h4>
                      <span>{product.soldOut ? 'Si' : 'No'}</span>
                    </div>
                    <div className="card__item">
                      <h4>Publicado</h4>
                      <span>{product.published ? 'Si' : 'No'}</span>
                    </div>
                    <div className="card__item">
                      <h4>Tiene descuento</h4>
                      <span>{product.hasDiscount ? 'Si' : 'No'}</span>
                    </div>
                    <div className="card__item">
                      <h4>Valor del descuento</h4>
                      <span>{product.discountValue}</span>
                    </div>
                    <div className="card__item">
                      <h4>Imagenes</h4>
                      <div className='flex wrap'>
                        {
                          product.images.map(image => (
                            <div key={image} className='mr-20 mb-20'>
                              <img style={{ width: 150, flexShrink: 0 }} src={image} alt="" />
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </>
              }
            </div>
          </>
      }
    </div >
  )
}
