import { DatePicker, Input, Table, Select, Divider, message } from 'antd'
import axios from 'axios'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { LinksLayout } from '../components/LinksLayout'
import { VerticalTable } from '../components/VerticalTable'
import { Order } from '../interfaces/Order'
import { PlusOutlined } from '@ant-design/icons';
import { PDFDownloadLink } from '@react-pdf/renderer'
import { AuthContext } from '../context/AuthProvider'
import { numberWithCommas } from '../helpers/numberWithCommas'
import { DriversReport } from '../components/DriversReport'
import { api } from '../api/api'


const DeliveryReport = () => {

  const links = [
    {
      to: '/reportes/rutas',
      name: 'Rutas'
    },
    {
      to: '/reportes/repartidores',
      name: 'Repartidores'
    },
    {
      to: '/reportes/ventas',
      name: 'Ventas'
    },
  ]

  const [orders, setOrders] = useState<Order[]>([])
  const [tempOrders, setTempOrders] = useState<Order[]>([])
  const [loading, setLoading] = useState(true)

  const [drivers, setDrivers] = useState<any>([])
  const [doc, setDoc] = useState(false)
  const [name, setName] = useState('')
  const [driver, setDriver] = useState(null)
  const { user } = useContext(AuthContext)
  const [date, setDate] = useState('')


  useEffect(() => {
    api.get(`/api/orders/`,
      {
        headers: {
          'Content-type': 'application/json',
          'x-token': user.token
        }
      }
    ).then(({ data }) => {
      setOrders(data.orders.filter((ord: Order) => ord.status !== 'Cancelado').sort((a: Order, b: Order) => {
        let aNumber = +a.number.substring(3, 6)
        let bNumber = +b.number.substring(3, 6)
        return (
          bNumber - aNumber
        )
      }))
      setTempOrders(data.orders)
      setLoading(false)
    })

    api.get(`/api/delivery/`).then(({ data }) => {
      setDrivers(data)
    })
  }, [user.token])


  const onNameChange = (event: any) => {
    setName(event.target.value)
  };

  const addItem = () => {
    api.post(`/api/delivery/`, {
      name
    }).then(() => {
      api.get(`/api/delivery/`).then(({ data }) => {
        setDrivers(data)
      })
      setName('')
    })
  };

  if (loading) return <span>Loading...</span>



  const headers = [
    {
      title: 'No. de pedido',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Productos',
      dataIndex: 'products',
      key: 'products',
      render: (_text: string, record: Order) => {
        return record.cart ?
          <div className='flex column'>
            {
              record.cart.products.map(item => (
                <div className='flex'>
                  <span>-</span>
                  <span className='ml-10'>{item.name}</span>
                </div>
              ))
            }
          </div>
          :
          record.products?.map(product => {
            return (
              <div className='flex'>
                <span>-</span>
                <span className='ml-10'>{product.name}</span>
              </div>
            )

          }
          )
      },
      width: 200
    },

    {
      title: 'Fecha de entrega',
      dataIndex: 'deliveryDate',
      key: 'deliveryDate',
      render: (_text: string, record: Order) => moment(record.deliveryDate).format('ll')
    },
    {
      title: 'Direccion de entrega',
      dataIndex: 'city',
      key: 'city',
      render: (text: string, record: Order) => <span
      >{record.shippingAddress?.street}, {record.shippingAddress?.colonia}, {record.shippingAddress?.postalCode}, {record.shippingAddress?.city}, Nuevo Leon
      </span>
    },
    {
      title: 'Repartidor',
      dataIndex: 'driver',
      key: 'driver',
    },
    {
      title: 'Total',
      dataIndex: 'shippingFee',
      key: 'shippingFee',
      render: (text: string) => numberWithCommas(+text)
    },
    {
      title: 'Detalles',
      dataIndex: 'detalles',
      key: 'detalles',
      render: (_text: string, record: Order) => <Link to={`/pedidos/${record.number}`} className='btn btn-small btn-black'>Ver</Link>
    }
  ]

  const filter = (e: any) => {
    if (e) {
      const start = moment(e[0]).format('ll')
      const end = moment(e[1]).format('ll')
      let filtered = []
      //if(driver)

      if (driver) {


        if (start === end) {
          filtered = tempOrders.filter(order => (moment(order.deliveryDate).format('ll') === start || moment(order.deliveryDate).format('ll') === end) &&
            order.driver === driver
          )
          setDate(start)
        } else {
          const start = moment(e[0]).format('YYYY-MM-DD')
          const end = moment(e[1]).format('YYYY-MM-DD')
          filtered = tempOrders.filter(order => moment(moment(order.deliveryDate).format('YYYY-MM-DD')).isBetween(start, end, undefined, '[]') && order.driver === driver)
        }
        setOrders(filtered)
      } else {

        if (start === end) {
          filtered = tempOrders.filter(order => moment(order.deliveryDate).format('ll') === start || moment(order.deliveryDate).format('ll') === end)
          setDate(start)
        } else {
          const start = moment(e[0]).format('YYYY-MM-DD')
          const end = moment(e[1]).format('YYYY-MM-DD')
          filtered = tempOrders.filter(order => moment(moment(order.deliveryDate).format('YYYY-MM-DD')).isBetween(start, end, undefined, '[]'))
        }
        setOrders(filtered)
      }


    }
  }

  const filterByDriver = (e: any) => {
    if (e === 'all') {
      setOrders(tempOrders)
      setDriver(null)
    } else {
      setDriver(e)
      setOrders(tempOrders.filter((item: any) => item.driver === e))
    }
  }

  const renderTotal = () => {

    function add(accumulator: number, a: number) {
      return accumulator + a;
    }
    let sum

    if (!driver) {
      sum = orders.map(order => order.shippingFee).reduce(add, 0); // with 
    } else {
      sum = orders.filter(order => order.driver === driver).map(order => order.shippingFee).reduce(add, 0); // with 

    }

    return sum

  }


  return (
    <LinksLayout links={links} title='Reporte de repartidores'>
      <div className='overviewPage'>

        <div className="overviewPage__content">
          <div className='flex justify-between align-center mb-20'>
            <h2>Filtrar pedidos por rango o por dia</h2>

            {
              doc ? <div className="download-btn-wrapper">
                <PDFDownloadLink
                  document={<DriversReport
                    date={date}
                    data={orders}
                    total={`$ ${numberWithCommas(renderTotal())} MXN`}
                  />
                  }
                  fileName={`Pago de repartidores`}>
                  {({ loading }) => (loading ?
                    <button className='btn' disabled>Generando reporte...</button> :
                    <button
                      className='btn btn-black'
                      onClick={() => { setDoc(false) }}
                    >Descargar</button>)}
                </PDFDownloadLink>
              </div> :
                <div style={{ display: 'flex' }}>
                  <button
                    className='btn btn-black'
                    onClick={() => { setDoc(true) }}
                  >
                    Generar reporte
                  </button>
                </div>
            }
          </div>
          <div className='mb-20'>
            {/* <Select onChange={filterByDriver} placeholder='Repartidor' style={{ width: 250, marginRight: 20 }}>
              <Select.Option value='all'>
                Mostrar todo
              </Select.Option>
              {
                drivers.map((driver: any) => (
                  <Select.Option key={driver.name} value={driver.name}>
                    {driver.name}
                  </Select.Option>
                ))
              }
            </Select> */}
            <Select
              onChange={filterByDriver} placeholder='Repartidor'
              style={{ width: 250, marginRight: 20 }}
              dropdownRender={menu => (
                <div>
                  {menu}
                  <Divider style={{ margin: '4px 0' }} />
                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                    <Input style={{ flex: 'auto' }} value={name} onChange={onNameChange} />
                    <span
                      style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                      onClick={addItem}
                    >
                      <PlusOutlined />
                    </span>
                  </div>
                </div>
              )}
            >
              {drivers.map((item: any) => (
                <Select.Option value={item.name} key={item.name}>{item.name}</Select.Option>
              ))}
            </Select>
            <DatePicker.RangePicker onChange={filter} format='DD-MMM-YYYY' />
          </div>
          <div className="mb-20">
            <h3>Mostrando {orders.length} pedidos</h3>
            <h2>Gastos de envío: ${numberWithCommas(renderTotal())} MXN</h2>
          </div>
          <div className='mobile-table'>
            <VerticalTable data={orders} headers={headers} />
          </div>
          <div className='web-table'>
            <Table dataSource={orders} size='small' columns={headers} />
          </div>
        </div>
      </div>
    </LinksLayout>
  )
}

export default DeliveryReport
