import { Input, Table } from 'antd'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { api } from '../api/api'
import { VerticalTable } from '../components/VerticalTable'
import { AuthContext } from '../context/AuthProvider'
import { User } from '../interfaces/User'

export const Customers = () => {

  const [customers, setCustomers] = useState<User[]>([])
  const [tempCustomers, setTempCustomers] = useState<User[]>([])
  const { user } = useContext(AuthContext)

  useEffect(() => {
    api.get(`/api/users?role=customer`,
      {
        headers: {
          'Content-type': 'application/json',
          'x-token': user.token
        }
      }
    ).then(({ data }) => {
      setCustomers(data.users)
      setTempCustomers(data.users)
    })
  }, [user.token])


  const handleSearch = (e: any) => {
    const searchTerm = e.target.value.toLowerCase()
    setCustomers(tempCustomers.filter((customer) => customer.name?.toLowerCase().includes(searchTerm) || customer.email?.toLowerCase().includes(searchTerm)))
  }

  const columns = [
    {
      title: 'Nombre de cliente',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Miembro desde',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (text: string, record: User) => moment(record.createdDate).format('ll')
    },
    {
      title: 'Detalles',
      dataIndex: 'details',
      key: 'details',
      render: (text: string, record: User) => <Link to={`/clientes/${record.id}`} className='btn btn-black btn-small'>Ver detalles</Link>
    },
  ]


  return (
    <div className='overviewPage'>
      <h2 className='overviewPage__title'>Clientes</h2>

      <div className='overviewPage__content'>
        <div className='mb-20'>
          <h2>Todos los clientes</h2>
          <Input onChange={handleSearch} placeholder='Buscar clientes por correo o nombre' />
        </div>
        <div className='mobile-table'>
          <VerticalTable data={customers} headers={columns} />
        </div>
        <div className='web-table'>
          <Table dataSource={customers} columns={columns} size='small' />
        </div>
      </div>
    </div>
  )
}
