import { Tag, Table } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { api } from '../api/api'
import { VerticalTable } from '../components/VerticalTable'
import { AuthContext } from '../context/AuthProvider'


export const Slides = () => {
  const [slides, setSlides] = useState([])
  const { user } = useContext(AuthContext)

  useEffect(() => {
    api.get(`/api/slides/`).then(({ data }) => {
      setSlides(data.slides)
    })
  }, [])

  const headers = [
    {
      title: 'Titulo',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Subtitulo',
      dataIndex: 'subtitle',
      key: 'subtitle'
    },
    {
      title: 'Posición',
      dataIndex: 'position',
      key: 'position'
    },
    {
      title: 'Accion principal',
      dataIndex: 'ctaText',
      key: 'ctaText'
    },
    {
      title: 'Redirige a',
      dataIndex: 'ctaRedirect',
      key: 'ctaRedirect'
    },
    {
      title: 'Activa',
      dataIndex: 'active',
      key: 'active',
      render: (text: string, record: any) => <Tag color={record.active ? 'green' : 'cyan'}>{record.active ? 'Si' : 'No'}</Tag>
    },
    {
      title: 'Detalles',
      dataIndex: 'detalles',
      key: 'detalles',
      render: (text: string, record: any) => <Link to={`/general/slides/${record.id}`} className='btn btn-small btn-black'>Ver</Link>
    }
  ]

  return (

    <div className='overviewPage'>
      <div className="overviewPage__content">
        <div className='flex justify-between align-center mb-20'>
          <h2>Slides</h2>
          <Link to='/general/agregar-slide' className='btn btn-black'>Agregar slide</Link>
        </div>
        <div className='mobile-table'>
          <VerticalTable data={slides} headers={headers} />
        </div>
        <div className='web-table'>
          <Table size='small' dataSource={slides} columns={headers} />
        </div>
      </div>
    </div>

  )
}
