import { Tag, Table } from 'antd'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { VerticalTable } from '../../../components/VerticalTable'
import { LinksLayout } from '../../../components/LinksLayout'
import { api } from '../../../api/api'
import { Category } from '../../../interfaces/Category'

export const Categories = () => {
  const [categories, setCategories] = useState([])

  useEffect(() => {
    api.get(`/api/categories/`).then(({ data }) => {
      setCategories(data.categories)
    })
  }, [])


  const headers = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Código',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Activa',
      dataIndex: 'active',
      key: 'active',
      render: (text: string, record: Category) => <Tag color={record.active ? 'green' : 'cyan'}>{record.active ? 'Si' : 'No'}</Tag>
    },
    {
      title: 'Detalles',
      dataIndex: 'detalles',
      key: 'detalles',
      render: (text: string, record: Category) => <Link to={`/productos/categorias/${record.code}`} className='btn btn-small btn-black'>Ver</Link>
    }
  ]

  return (
    <LinksLayout title='Categorías'>
      <div className='overviewPage'>
        <div className="overviewPage__content">
          <div className='flex justify-between align-center mb-20'>
            <h2>Todas las categorias</h2>
            <Link to='/productos/categorias/agregar-categoria' className='btn btn-black'>Agregar categoria</Link>
          </div>
          <div className='mobile-table'>
            <VerticalTable data={categories} headers={headers} />
          </div>
          <div className='web-table'>
            <Table size='small' dataSource={categories} columns={headers} />
          </div>
        </div>
      </div>
    </LinksLayout>
  )
}
