import { Modal, Tag } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { api } from '../api/api'
import { Order } from '../interfaces/Order'
import { User } from '../interfaces/User'

export const Account = () => {

  const { id } = useParams<any>()
  const [order, setOrder] = useState<any>(null)
  const [visible, setvisible] = useState(false)
  const [account, setAccount] = useState<User>({} as User)
  const [cart, setCart] = useState<any>({})

  useEffect(() => {
    api.get(`/api/users/${id}`).then(({ data }) => {
      setAccount(data)
    })
  }, [id])

  const columns = [
    {
      title: 'Numero de pedido',
      dataIndex: 'number',
      key: 'number'
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (text: string, record: Order) => `$${record.total} MXN`
    },
    {
      title: 'Fecha de pedido',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (text: string, record: Order) => moment(record.createdDate).format('lll')
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status'
    },
   /*  {
      title: 'Detalles',
      dataIndex: 'details',
      key: 'details',
      render: (text: string, record: Order) => <button className='btn btn-black btn-small' onClick={() => {
        setvisible(true)
        setOrder(record)
        api.get(`/api/products/cart/${record.cart}`).then(({ data }) => {
          setCart(data)
        })

      }}>Ver detalles</button>
    }, */

  ]

  const isJson = (str: string) => {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  return (
    <div className='account'>
      <div className="account__info">
        <div className="account__infoDetails">
          <span>
            Nombre: {account.name}
          </span>
          <span>
            Email: {account.email}
          </span>
        </div>
      </div>
      <div className="account__orders">
        <h2>Historial de pedidos</h2>
       {/*  <Table dataSource={account.orders} columns={columns} /> */}
      </div>

      <Modal onCancel={() => { setvisible(false) }} onOk={() => { setvisible(false) }} title='Detalles del pedido' visible={visible}>

        <div className="card mr-20">
          <div className='flex justify-between'>
            <div className='card__item'>
              <h4>Numero de pedido</h4>
              <span>{order?.number}</span>
            </div>

            <div className='card__item'>
              <Tag color={order?.status === 'Nuevo' ? '#2db7f5' : order?.status === 'Enviado' ? '#faad14' : order?.status === 'Entregado' ? '#87d068' : '#d9363e'}>{order?.status}</Tag>
            </div>
          </div>
          <div className='card__item'>
            <h4>ID de pedido</h4>
            <span>{order?.cart.id}</span>
          </div>
          <div className='card__item'>
            <h4>Fecha de pedido</h4>
            <span>{moment(order?.createdDate).format('lll')}</span>
          </div>
          <div className='card__item'>
            <h4>Fecha de entrega</h4>
            <span>{moment(order?.deliveryDate).format('ll')}</span>
            <span>Turno de {order?.deliveryTime}</span>
          </div>
          <div className='card__item'>
            <h4>Anonimo</h4>
            <span>Sí</span>
          </div>
          <div className='card__item'>
            <h4>Dirección de entrega</h4>
            <span>{order?.shippingAddress.street}, {order?.shippingAddress.colonia}, {order?.shippingAddress.city}, Nuevo Leon</span>
          </div>
          <div className='card__item'>
            <h4>Nombre de quien recibe</h4>
            <span>{order?.receiverName}</span>
          </div>
          <div className='card__item'>
            <h4>Telefono de quien recibe</h4>
            <span>{order?.receiverPhone}</span>
          </div>
          <div className='card__item'>
            <h4>Mensaje en el arreglo</h4>
            <span>{order?.cardMessage}</span>
          </div>

          <div className='card__item'>
            <h4>Productos comprados</h4>
            <>
              {
                cart?.items?.map((product: any) => (
                  <div className='productItem' key={product.cart}>
                    <div className='productItem__info'>
                      <div className='productItem__img'>
                        <img src={product.img} alt={product.name} />
                      </div>
                      <div className='productItem__wrapper'>
                        <div className='productItem__name'>
                          <span>{product.name}</span>
                          <span>${product.finalPrice} MXN</span>
                        </div>
                        <div>
                          {
                            product.attributes.map((attribute: any) => {
                              return (
                                <div key={attribute.shortName} className='productItem__attribute'>
                                  <span className='productItem__attributeName'>{attribute.shortName}</span>
                                  {
                                    Array.isArray(attribute.values)
                                      ? attribute.values.map((value: string, index: number) => {
                                        return (
                                          <div key={index}>
                                            <span className='font-sm' key={index}>{JSON.parse(value).colorName}</span>
                                          </div>
                                        )
                                      })
                                      : <span className='font-sm' key={attribute.shortName}>{isJson(attribute.values) ? JSON.parse(attribute.values).optionName : attribute.values}</span>
                                  }
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>

                  </div>
                ))
              }
            </>
          </div>

          <div className='card__item'>
            <h4>Informacion de pago</h4>
            <span>Subtotal: ${order?.subTotal} MXN</span>
            <span>Envio: ${order?.shippingFee} MXN</span>
            <span><b>Total pagado: ${order?.total} MXN</b></span>
          </div>
        </div>

      </Modal>
    </div>
  )
}
