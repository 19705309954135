import { AppRouter } from './AppRouter'
import './styles/antd.css'
import './styles/Globals.scss'
import { AuthProvider } from './context/AuthProvider'

function App() {
  return (
    <AuthProvider>
      <AppRouter />
    </AuthProvider>
  )
}

export default App
