import { Button, Form, message } from 'antd'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import {
  ArrowLeftOutlined
} from '@ant-design/icons'
import { api } from '../../api/api';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../components/Input';
import Select from 'react-select'

export const CreateOrder = () => {

  const history = useHistory()
  const [saving, setSaving] = useState(false)
  const { register, handleSubmit, control, formState: { errors } } = useForm();

  const [products, setProducts] = useState([]);

  const onSubmit = async (data: any) => {
    setSaving(true)
    try {
      await api.post('/api/orders/create/manual', {
        ...data,
        shippingType: data.shippingType.value,
        products: data.products.map((option: any) => option.value),
        channel: data.channel.value
      })
      message.success('Orden creada con éxito')
      setSaving(false)
      history.replace('/pedidos')
    } catch (error: any) {
      message.error(error.response.data.message)
      setSaving(false)
    }
  }

  useEffect(() => {
    const fetchProducts = async () => {
      const { data } = await api.get('/api/products')
      setProducts(data.products)
    }
    fetchProducts()
  }, [])

  return (
    <div className='detailPage'>
      <div className="flex align-center justify-between mb-20">
        <div className="flex">
          <Button onClick={() => { history.goBack() }} className='mr-10' shape="circle" icon={<ArrowLeftOutlined />} />
          <h3>Crear pedido</h3>
        </div>

        <div className="flex">
          <button disabled={saving} onClick={handleSubmit(onSubmit)} className='btn btn-black'>{saving ? 'Guardando...' : 'Guardar'}</button>
        </div>

      </div>
      <div className="card">
        <h4>
          Datos personales y de entrega
        </h4>

        <div className="group">
          <Input
            name='customer'
            register={register}
            placeholder='Nombre y apellido'
            required
            label='Nombre y apellido'
            errors={errors}
          />
        </div>

        <div className="group">
          <Input
            type='email'
            name='email'
            register={register}
            placeholder='Correo electrónico'
            required
            label='Correo electrónico'
            errors={errors}
          />
        </div>

        <div className="group">
          <Input
            name='phone'
            register={register}
            placeholder='Teléfono de contacto'
            required
            label='Teléfono de contacto'
            errors={errors}
          />
        </div>
        <h4>Dirección de entrega</h4>
        <div className="group">
          <span>Tipo de envío</span>
          <Controller
            name="shippingType"
            control={control}
            rules={{
              required: true
            }}
            render={({ field: { onChange, onBlur, value } }) =>
              <Select
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                placeholder='Tipo de envío'
                isSearchable={true}
                options={[
                  {
                    label: 'Local',
                    value: 'Local'
                  },
                  {
                    label: 'Local express',
                    value: 'Local express'
                  },
                  {
                    label: 'Nacional',
                    value: 'Nacional'
                  },
                ]}
              />}
          />
          {errors.shippingType && <span className='error'>Campo requerido</span>}
        </div>

        <div className="group">
          <Input
            name='shippingAddress.street'
            register={register}
            placeholder='Calle y número'
            required
            label='Calle y número'
            errors={errors}
          />
        </div>
        <div className="group">
          <Input
            name='shippingAddress.colonia'
            register={register}
            placeholder='Colonia'
            required
            label='Colonia'
            errors={errors}
          />
        </div>
        <div className="group">
          <Input
            name='shippingAddress.postalCode'
            register={register}
            placeholder='Código postal'
            required
            label='Código postal'
            errors={errors}
          />
        </div>
        <div className="group">
          <Input
            name='shippingAddress.state'
            register={register}
            placeholder='Estado'
            required
            label='Estado'
            errors={errors}
          />
        </div>
        <div className="group">
          <Input
            name='shippingAddress.city'
            register={register}
            placeholder='Municipio'
            required
            label='Municipio'
            errors={errors}
          />
        </div>
        <div className="group">
          <span>Productos vendidos</span>
          <Controller
            name="products"
            control={control}
            rules={{
              required: true
            }}
            render={({ field: { onChange, onBlur, value } }) =>
              <Select
                isMulti
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                placeholder='Productos vendidos'
                isSearchable={true}
                options={products.map((product: any) => (
                  {
                    label: product.name,
                    value: product.id
                  }
                ))}
              />}
          />
          {errors.products && <span className='error'>Campo requerido</span>}
        </div>

        <div className="group">
          <span>Canal de venta</span>
          <Controller
            name="channel"
            control={control}
            rules={{
              required: true
            }}
            render={({ field: { onChange, onBlur, value } }) =>
              <Select
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                placeholder='Canal de venta'
                isSearchable={true}
                options={[
                  {
                    label: 'Facebook',
                    value: 'Facebook'
                  },
                  {
                    label: 'Instagram',
                    value: 'Instagram'
                  },
                  {
                    label: 'WhatsApp',
                    value: 'WhatsApp'
                  },
                ]}
              />}
          />
          {errors.channel && <span className='error'>Campo requerido</span>}
        </div>

        <div className="group">
          <Input
            type='number'
            name='subTotal'
            register={register}
            placeholder='Subtotal'
            required
            label='Subtotal'
            errors={errors}
          />
        </div>
        <div className="group">
          <Input
            type='number'
            name='shippingFee'
            register={register}
            placeholder='Envío'
            required
            label='Envío'
            errors={errors}
          />
        </div>
        <div className="group">
          <Input
            type='number'
            name='total'
            register={register}
            placeholder='Total'
            required
            label='Total'
            errors={errors}
          />
        </div>
      </div>
    </div>
  )
}
