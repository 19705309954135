import { useContext } from 'react'
import {
  BrowserRouter as Router,
  Switch
} from 'react-router-dom'
import { Login } from './pages/Login'
import PublicRoute from './components/PublicRoute'
import PrivateRoute from './components/PrivateRoute'
import ProtectedRoutes from './components/ProtectedRoutes'
import { AuthContext } from './context/AuthProvider'

export const AppRouter = () => {

  const { user, loading } = useContext(AuthContext)

  if (loading) return <div>Loading...</div>


  return (
    <Router>
      <div>
        <Switch>
          <PublicRoute
            path="/login"
            isAuthenticated={user.token}
          >
            <Login />
          </PublicRoute>
          <PrivateRoute
            path="/"
            isAuthenticated={user.token}
          >
            <ProtectedRoutes />
          </PrivateRoute>
        </Switch>
      </div>
    </Router>
  )
}
