import { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import 'moment/locale/es' // without this line it didn't work
import '../styles/Calendar.scss'
import { Order } from '../interfaces/Order'
import { AuthContext } from '../context/AuthProvider'
import { numberWithCommas } from '../helpers/numberWithCommas'
import { getDaysInAMonth } from '../helpers/calendar'
import { api } from '../api/api'
moment.locale('es')

export const Calendar = () => {
  const [currentMonth, setCurrentMonth] = useState<any>(new Date())
  const { user } = useContext(AuthContext)
  const [orders, setOrders] = useState<Order[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    api.get('/api/orders',
      {
        headers: {
          'Content-type': 'application/json',
          'x-token': user.token
        }
      }
    ).then(({ data }) => {
      setOrders(data.orders.filter((ord: Order) => ord.status !== 'Cancelado').sort((a: Order, b: Order) => {
        let aNumber = +a.number.substring(3, 6)
        let bNumber = +b.number.substring(3, 6)
        return (
          bNumber - aNumber
        )
      }))
      setLoading(false)
    })
  }, [user.token])

  if (loading) return <span>Loading...</span>

  const nextMonth = () => {
    setCurrentMonth(moment(currentMonth).startOf('month').add(1, 'M'))
  }

  const prevMonth = () => {
    setCurrentMonth(moment(currentMonth).startOf('month').subtract(1, 'M'))
  }

  const renderSummary = (date: string) => {
    function add(accumulator: number, a: number) {
      return accumulator + a;
    }
    const sum = orders.filter(order => moment(order.createdDate).format('ll') === date).map(order => order.total - order.shippingFee).reduce(add, 0);
    return sum

  }

  const renderMonthlySales = () => {
    const month = moment(currentMonth).format('MM y')
    function add(accumulator: number, a: number) {
      return accumulator + a;
    }
    const sum = orders.filter(order => moment(order.createdDate).format('MM y') === month).map(order => order.total - order.shippingFee).reduce(add, 0); // with 
    return sum

  }

  const renderWeekly = (start: any, end: any) => {

    function add(accumulator: number, a: number) {
      return accumulator + a;
    }

    const weeklySummary = orders.filter(order => moment(order.createdDate).isBetween(start, end)).map(order => order.total - order.shippingFee).reduce(add, 0)

    return weeklySummary

  }

  return (
    <div id='calendar-component'>

      <div className="flex align-center justify-between">
        <div className='calendar-controls'>
          <div onClick={prevMonth}>
            <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M15 19l-7-7 7-7' />
            </svg>
          </div>
          <h2><span className='monthName'>{moment(currentMonth).format('MMMM')}</span>, {moment(currentMonth).format('yyyy')}</h2>
          <div onClick={nextMonth}>
            <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M9 5l7 7-7 7' />
            </svg>
          </div>
        </div>

        <div>
          <h3> Ventas del mes: ${numberWithCommas(renderMonthlySales())} MXN</h3>
        </div>
      </div>

      <div className='calendar-content display-month'>
        <div className='calendar-content-items month'>
          {
            getDaysInAMonth(+moment(currentMonth).format('YYYY'), +moment(currentMonth).format('MM') - 1).map((week, index) => {
              let start = moment(getDaysInAMonth(+moment(currentMonth).format('YYYY'), +moment(currentMonth).format('MM') - 1)[index][0])
              let end = moment(getDaysInAMonth(+moment(currentMonth).format('YYYY'), +moment(currentMonth).format('MM') - 1)[index][6]).add(1, 'day')
              return (
                <div className='item-row'>
                  <div className='col-header'>
                    <span>Mes</span>
                    <span>Dia</span>
                    <span>Monto (MXN)</span>
                  </div>
                  {
                    week.map((day: any) => (
                      <div className="current-month-day">

                        <span className='current-month-day-number number'>{moment(day).format('MMMM')}</span>
                        <span className='current-month-day-number number'>{moment(day).format('D')}</span>
                        <span className='current-month-day-number number'>$ {numberWithCommas(renderSummary(moment(day).format('ll')))}</span>
                      </div>
                    ))
                  }
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                      Total
                    </div>
                    <div>
                      {renderWeekly(start, end)}

                    </div>
                  </div>
                </div>
              )
            }

            )
          }
        </div>
      </div>

    </div>
  )
}
