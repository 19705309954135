import { Button, message, Tag } from 'antd'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { LoadingOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { AuthContext } from '../../context/AuthProvider'
import { api } from '../../api/api'
import { Controller, useForm } from 'react-hook-form'
import Input from '../../components/Input';
import Select from 'react-select'
import { Discount as IDiscount } from '../../interfaces/Discount';

export const Discount = () => {
  const { discountId } = useParams<any>()
  const [discount, setDiscount] = useState({} as IDiscount)
  const [editing, setEditing] = useState(false)
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const { user } = useContext(AuthContext)
  const [saving, setSaving] = useState(false)
  const { register, handleSubmit, control, formState: { errors } } = useForm();

  useEffect(() => {
    api.get(`/api/discounts/${discountId}`, {
      headers: {
        'Content-type': 'application/json',
        'x-token': user.token
      }
    }).then(({ data }) => {
      setDiscount(data.discount)
      setLoading(false)
    })
  }, [discountId, saving, user.token])

  const onSubmit = async (data: any) => {
    try {
      await api.put(`/api/discounts/${discount.id}`, {
        ...data,
        type: data.type.value
      })
      message.success('Descuento actualizado')
      setEditing(false)
      setDiscount({
        ...discount,
        ...data,
        type: data.type.value
      })
    } catch (error: any) {
      message.error(error.response.data.message)
    }
  }

  return (
    <div className='detailPage'>
      {
        loading ? <>
          <div className="flex align-center justify-between mb-20">
            <h3>Cargando...</h3>
            <LoadingOutlined style={{ fontSize: 30 }} />
          </div>
        </> :
          <>
            <div className="flex align-center justify-between mb-20">
              <div className="flex">
                <Button onClick={() => { history.goBack() }} className='mr-10' shape="circle" icon={<ArrowLeftOutlined />} />
                <h3>{discount.name}</h3>
              </div>
              {
                !editing && <button className='btn btn-black' onClick={() => { setEditing(true) }}>Editar</button>
              }
              {
                editing &&
                <div className="flex">
                  <button disabled={saving} onClick={() => { setEditing(false) }} className='btn btn-outlined mr-10'>Cancelar</button>
                  <button disabled={saving} onClick={handleSubmit(onSubmit)} className='btn btn-black'>{saving ? 'Guardando...' : 'Guardar'}</button>
                </div>
              }
            </div>
            <div className="card">
              {
                editing ?
                  <div>
                    <div className="group">
                      <Input
                        required
                        defaultValue={discount.name}
                        register={register}
                        placeholder='Nombre de descuento'
                        name='name'
                        label='Nombre de descuento'
                        errors={errors}
                      />
                    </div>
                    <div className="group">
                      <span>Tipo de descuento</span>
                      <Controller
                        rules={{
                          required: true,
                        }}
                        name="type"
                        control={control}
                        defaultValue={{
                          label: discount.type === 'percentage' ? 'Porcentaje' : 'Monto fijo',
                          value: discount.type
                        }}
                        render={({ field: { onChange, onBlur, value } }) =>
                          <Select
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            placeholder='Tipo de descuento'
                            isSearchable={true}
                            options={[
                              {
                                label: 'Porcentaje',
                                value: 'percentage'
                              },
                              {
                                label: 'Monto fijo',
                                value: 'fixed'
                              },
                            ]}
                          />}
                      />
                      {errors.type && <span className='error'>Campo requerido</span>}
                    </div>
                    <div className="group">
                      <Input
                        defaultValue={discount.value}
                        type='number'
                        required
                        register={register}
                        placeholder='Valor del descuento'
                        name='value'
                        label='Valor del descuento'
                        errors={errors}
                      />
                    </div>
                    <div className="group">
                      <input defaultChecked={discount.active} {...register('active')} type="checkbox" name="active" id="active" />
                      <label htmlFor="active">Activo</label>
                    </div>
                  </div> :
                  <>
                    <div className="flex justify-between align-center">
                      <div className="card__item">
                        <h4>Código de descuento</h4>
                        <span>{discount.name}</span>
                      </div>
                      <Tag color={discount.active ? 'rgb(135, 208, 104)' : 'rgb(155 155 155)'}>{discount.active ? 'Activo' : 'Vencido'}</Tag>
                    </div>
                    <div className="card__item">
                      <h4>Tipo de descuento</h4>
                      <span>{discount.type === 'percentage' ? 'Porcentaje' : 'Monto fijo'}</span>
                    </div>
                    <div className="card__item">
                      <h4>Valor del descuento</h4>
                      <span>{discount.type === 'percentage' ? `${discount.value} %` : `$${discount.value} MXN`}</span>
                    </div>
                  </>
              }
            </div>
          </>
      }
    </div>
  )
}
