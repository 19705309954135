import { Button, Checkbox, Form, message } from 'antd'
import axios from 'axios'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { MinusCircleTwoTone } from '@ant-design/icons'
import { LoadingOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { api } from '../api/api'
import { AuthContext } from '../context/AuthProvider'
import { Controller, useForm } from 'react-hook-form'
import imageCompression from 'browser-image-compression';
import Select from 'react-select';
import { Product } from '../interfaces/Product'
import { Category } from '../interfaces/Category'
import Input from '../components/Input'



export const Slide = () => {

  const { id } = useParams<any>()
  const [editing, setEditing] = useState(false)
  const imageRef = useRef<any>()
  const [image, setImage] = useState('')
  const [uploading, setUploading] = useState(false)
  const [saving, setSaving] = useState(false)
  const history = useHistory()
  const [slide, setSlide] = useState({} as any)
  const [loading, setLoading] = useState(true)
  const { user } = useContext(AuthContext)
  const { register, handleSubmit, control, formState: { errors } } = useForm();
  const [type, setType] = useState('')

  const [products, setProducts] = useState<Product[]>([])
  const [categories, setCategories] = useState<Category[]>([])


  useEffect(() => {
    api.get(`/api/slides/${id}`).then(({ data }) => {
      setSlide(data.slide)
      setLoading(false)
      setImage(data.slide.image)
      setType(data.slide.type)
    })
    const fetchData = async () => {
      try {
        const { data: categoriesData } = await api.get('/api/categories')
        const { data: productsData } = await api.get('/api/products')

        setCategories(categoriesData.categories)
        setProducts(productsData.products)

      } catch (error) {

      }
    }
    fetchData()
  }, [id, saving])

  const handleFileUpload = async (e: any) => {
    const file = e.target.files[0]
    if (!file) {
      return
    }
    setUploading(true)
    const options = {
      maxSizeMB: 6,
      maxWidthOrHeight: 3000,
      useWebWorker: true,
    }
    let compressedBlob
    let compressedFile

    const formData = new FormData()
    try {
      compressedBlob = await imageCompression(file, options);
      compressedFile = new File([compressedBlob], compressedBlob.name)
    } catch (error) {
    }

    formData.append('image', compressedFile as any)

    try {
      const { data } = await api.post('/api/slides/upload', formData,
        {
          headers: {
            'Content-type': 'application/json',
            'x-token': user.token
          }
        }
      )
      message.success('Imagen cargada')
      setUploading(false)
      setImage(data.Location)
    } catch (error: any) {
      message.error(error.response.data.message)
      setUploading(false)
    }
  }

  const onSubmit = async (data: any) => {
    if (image === '') return message.error('Elige una imagen para la categoria')
    try {
      const update = {
        ...data,
        image,
        type: data.type?.value,
        ctaRedirect: data.type?.value === 'product' ? `/shop/${data.product?.value}` : `/categories/${data.category?.value}`
      }
      await api.put(`/api/slides/${slide.id}`, update)
      message.success('Slide actualizada')
    } catch (error: any) {
      message.error(error.response.data.message)
    }

  }

  return (
    <div className='detailPage'>

      {
        loading ? <>
          <div className="flex align-center justify-between mb-20">
            <h3>Cargando...</h3>
            <LoadingOutlined style={{ fontSize: 30 }} />
          </div>
        </> : <>

          <div className="flex align-center justify-between mb-20">
            <div className="flex">
              <Button onClick={() => { history.goBack() }} className='mr-10' shape="circle" icon={<ArrowLeftOutlined />} />
              <h3>{slide.name}</h3>
            </div>
            {
              !editing && <button className='btn btn-black' onClick={() => { setEditing(true) }}>Editar</button>
            }
            {
              editing &&
              <div className="flex">
                <button disabled={saving} onClick={() => { setEditing(false) }} className='btn btn-outlined mr-10'>Cancelar</button>
                <button disabled={saving || uploading} onClick={handleSubmit(onSubmit)} className='btn btn-black'>{saving ? 'Guardando...' : uploading ? 'Subiendo imagen...' : 'Guardar'}</button>
              </div>
            }
          </div>

          <div className="card">
            {
              editing ?
                <div className='form__container'>
                  <div className='group'>
                    <span>Imagen de slide</span>
                    <input
                      onChange={handleFileUpload}
                      ref={imageRef}
                      style={{ display: 'none' }}
                      type='file'
                    />
                    <div>
                      {
                        image !== '' &&
                        <button
                          type='button'
                          className='imagePreview__img'
                          style={{
                            marginBottom: 30
                          }}
                        >
                          <img style={{ width: '100%', display: 'block' }} src={image} alt='' />
                        </button>
                      }
                      <div onClick={() => { imageRef.current.click() }} style={
                        {
                          width: '100%',
                          height: 100,
                          border: '2px dashed #cdcdcd',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textAlign: 'center',
                          fontSize: 14,
                          cursor: 'pointer',
                        }
                      }>
                        <span>
                          {image !== '' ? 'Cambiar' : 'Elegir'} imagen
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="group">
                    <Input
                      register={register}
                      defaultValue={slide.title}
                      placeholder='Titulo'
                      name='title'
                      errors={errors}
                      label='Titulo'
                    />
                  </div>
                  <div className="group">
                    <Input
                      register={register}
                      defaultValue={slide.subtitle}
                      placeholder='Subtitulo'
                      name='subtitle'
                      errors={errors}
                      label='Subtitulo'
                    />
                  </div>
                  <div className="group">
                    <Input
                      type='number'
                      register={register}
                      defaultValue={slide.position}
                      placeholder='Posición (numero de slide)'
                      name='position'
                      errors={errors}
                      label='Posición (numero de slide)'
                    />
                  </div>
                  <div className="group">
                    <Input
                      register={register}
                      defaultValue={slide.ctaText}
                      label='Texto de accion principal'
                      name='ctaText'
                      errors={errors}
                      placeholder='"Explorar", "Comprar", etc'
                    />
                  </div>
                  <div className="group">
                    <span>Acción principal redirige hacia:</span>
                    <Controller
                      name="type"
                      defaultValue={{
                        label: slide.type,
                        value: slide.type
                      }}
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) =>
                        <Select
                          onChange={(e) => {
                            onChange(e)
                            setType(e.value)
                          }}
                          onBlur={onBlur}
                          value={value}
                          placeholder='Elegir'
                          options={[
                            {
                              label: 'Un producto',
                              value: 'product'
                            },
                            {
                              label: 'Una categoría',
                              value: 'category'
                            },
                          ]}
                        />}
                    />
                  </div>
                  {
                    type === 'product' &&
                    <div className="group">
                      <span>Elige producto a redirigir en accion principal:</span>
                      <Controller
                        name="product"
                        defaultValue={[]}
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) =>
                          <Select
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            placeholder='Elegir'
                            options={products.map(item => (
                              {
                                label: item.name,
                                value: item.code
                              }
                            ))}
                          />}
                      />
                    </div>
                  }
                  {
                    type === 'category' &&
                    <div className="group">
                      <span>Elige categoría a redirigir en accion principal:</span>
                      <Controller
                        name="category"
                        defaultValue={[]}
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) =>
                          <Select
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            placeholder='Elegir'
                            options={categories.map(item => (
                              {
                                label: item.name,
                                value: item.code
                              }
                            ))}
                          />}
                      />
                    </div>
                  }
                </div>
                :
                <>
                  <div className="card__item">
                    <h4>Titulo</h4>
                    <span>{slide.title}</span>
                  </div>
                  <div className="card__item">
                    <h4>Subtitulo</h4>
                    <span>{slide.subtitle}</span>
                  </div>
                  <div className="card__item">
                    <h4>Posición (Numero de slide)</h4>
                    <span>{slide.position}</span>
                  </div>
                  <div className="card__item">
                    <h4>Acción principal</h4>
                    <span>{slide.ctaText}</span>
                  </div>
                  <div className="card__item">
                    <h4>Redirige a </h4>
                    <span>{slide.ctaRedirect}</span>
                  </div>
                  <div className="card__item">
                    <h4>Activa</h4>
                    <span>{slide.active ? 'Si' : 'No'}</span>
                  </div>
                  <div className="card__item">
                    <h4>Imagen en home</h4>
                    <img style={{ width: '100%' }} src={slide.image} alt="" />
                  </div>
                </>
            }
          </div>
        </>
      }

    </div>
  )
}
