import { Empty } from 'antd'
import React from 'react'

export const VerticalTable = ({ data, headers }: any) => {
  return (
    <div className='vertical-table-container'>
      {
        (data && data.length > 0)
          ? data.map((item: any) => (
            <div key={item.id} className='vertical-table'>
              {
                headers.map((header: any) => (
                  <div key={header.title} className='vertical-table-row'>
                    <div className='vertical-table-header'><span>{header.title}</span></div>
                    {
                      ('render' in header)
                        ? <div className='vertical-table-data'>{header.render(item[header.dataIndex], item)}</div>
                        : <div className='vertical-table-data'>{item[header.dataIndex]}</div>
                    }
                  </div>
                ))
              }
            </div>
          )
          )
          : <div><Empty /></div>
      }
    </div>
  )
}
