import { Route, Switch, Redirect } from 'react-router-dom';
import routes from '../helpers/routes'; // Route list
import { Sidebar } from './Sidebar';

const ProtectedRoutes = () => (
  <Switch>
    {routes.map(({ component: Component, path, exact }) => (
      <Route
        path={`/${path}`}
        key={path}
        exact={exact}
      >
        <Sidebar />
        <div className='main__content'
        >
          <Component />
        </div>
      </Route>
    ))}

    <Redirect to='/pedidos' />

  </Switch>
);

export default ProtectedRoutes;