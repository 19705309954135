import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({

  font: {
    fontSize: 10,

  },
  fontBold: {

  },
  uppercase: {
    textTransform: 'uppercase'
  },
  spacer: {
    marginBottom: 20
  },

  body: {
    padding: 40
  },

  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'row',
  },
  col: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    padding: 5
  },

  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  }
});
