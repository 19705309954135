import { Button } from 'antd';
import axios from 'axios';
import imageCompression from 'browser-image-compression';
import React, { useContext, useRef } from 'react'
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { MinusCircleTwoTone } from '@ant-design/icons'
import { AuthContext } from '../context/AuthProvider';
import { api } from '../api/api';
import styles from '../styles/Upload.module.scss'

const SortableItem = sortableElement(({ image, index, images, setImages }) => (
  <div key={image} className={styles.preview}>
    <img src={image} alt='' />
    <Button
      shape='circle'
      icon={<MinusCircleTwoTone twoToneColor='red' />}
      type='link'
      onClick={() => {
        const newImages = [...images]
        newImages.splice(index, 1)
        setImages(images.filter(picture => picture !== image))
      }}
    />
  </div>

));

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <div className={styles.previews}>
      {
        children
      }
    </div>
  )
});

export const Upload = ({ uploading, setUploading, images, setImages, url }) => {

  const { user } = useContext(AuthContext)

  const fileInputRef = useRef()

  function arrayMove(array, from, to) {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setImages(arrayMove(images, oldIndex, newIndex))

  };

  const handleClick = (e) => {
    e.preventDefault()
    fileInputRef.current.click()
  }


  const handleFileUpload = async (e) => {
    const files = e.target.files
    if (!files || files.length === 0) {
      return
    }
    setUploading(true)
    const options = {
      maxSizeMB: 6,
      maxWidthOrHeight: 3000,
      useWebWorker: true,
    }
    let compressedBlob
    let compressedFile

    const formData = new FormData()
    for (let index = 0; index < files.length; index++) {
      const file = files[index]
      try {
        compressedBlob = await imageCompression(file, options);
        compressedFile = new File([compressedBlob], compressedBlob.name)
      } catch (error) {
      }
      formData.append('images', compressedFile)
    }

    api.post(url, formData,
      {
        headers: {
          'Content-type': 'application/json',
          'x-token': user.token
        }
      }
    ).then(({ data }) => {
      let images = data.map(image => image.Location);
      setImages(prev => [...prev, ...images])
      setUploading(false)
    }).catch(() => {
      setUploading(false)
    })

  }


  return (
    <div className='images'>
      {
        images.length > 0 && <h3 className='block mb-20'>Puedes arrastrar y ordenar las imagenes.</h3>
      }
      {
        (images.length === 0 && !uploading) &&
        <div className={styles.dropZone}>
          <input multiple onChange={handleFileUpload} ref={fileInputRef} type='file' name='upload' id='' />
          <button onClick={handleClick} className='btn btn-black'>Cargar fotos</button>
        </div>
      }
      {
        uploading &&
        <div className={styles.dropZone}>
          <h2>Cargando imagenes...</h2>
        </div>

      }

      {
        (images.length > 0 && !uploading) &&
        <SortableContainer axis='xy' onSortEnd={onSortEnd}>
          {
            images.map((image, index) => (
              <SortableItem key={`item-${image}`} index={index} image={image} images={images} setImages={setImages} />
            ))
          }
          <div className={styles.dropZone}>
            <input multiple onChange={handleFileUpload} ref={fileInputRef} type='file' name='upload' id='' />
            <button onClick={handleClick} className='btn btn-black'>Agregar más fotos</button>
          </div>
        </SortableContainer>
      }
    </div>
  )
}
