import { Document, Page, Text, View } from "@react-pdf/renderer";
import { styles } from "../helpers/styles";
import { Order } from "../interfaces/Order";
import moment from "moment";

export const DriversReport = ({ data, date, total }: any) => {

  const headers = [
    {
      title: 'No. pedido',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Fecha entrega',
      dataIndex: 'deliveryDate',
      key: 'deliveryDate',
      render: (text: string) => <Text style={styles.font}>{moment(text).format('ll')}</Text>
    },
    {
      title: 'Direccion',
      dataIndex: 'shippingAddress',
      key: 'shippingAddress',
      render: (_text: string, record: Order) =>
        <Text style={styles.font}
        >{record.shippingAddress?.street}, {record.shippingAddress?.colonia}, {record.shippingAddress?.postalCode}, {record.shippingAddress?.city}, Nuevo Leon
        </Text>
    },
    {
      title: 'KM',
      dataIndex: 'km',
      key: 'km',
    },
    {
      title: 'Total envío',
      dataIndex: 'shippingFee',
      key: 'shippingFee',
    },


  ]

  const Table = ({ data, columns }: any) => {
    return (
      <View style={{ display: 'flex' }}>
        <View style={[styles.row]} fixed>
          {
            columns.map((col: any, index: number) => (
              index === 1 ?
                <View key={col.title} style={[styles.col, { flexGrow: 2 }]}>
                  <Text style={[styles.font, styles.fontBold]}>{col.title}</Text>
                </View> :
                <View key={col.title} style={styles.col}>
                  <Text style={[styles.font, styles.fontBold]}>{col.title}</Text>
                </View>
            ))
          }

        </View>
        <View >
          {
            data.map((item: any, index: number) => {
              return (
                <View key={index} style={[styles.row]}>
                  {
                    columns.map((col: any, index: number) => {
                      return (

                        index === 2 ?
                          <View key={index} style={[styles.col, { flexGrow: 2 }]}>
                            {
                              ('render' in col) ?
                                <View>
                                  {col.render(item[col.dataIndex], item)}
                                </View> :
                                <Text style={styles.font}>{item[col.dataIndex]}</Text>
                            }
                          </View> :
                          <View key={index} style={styles.col}>
                            {
                              ('render' in col) ?
                                <View>
                                  {col.render(item[col.dataIndex], item)}
                                </View> :
                                <Text style={styles.font}>{item[col.dataIndex]}</Text>
                            }
                          </View>
                      )
                    })
                  }
                </View>
              )
            }

            )
          }
        </View>
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'flex-end'
          }}
        >
          <Text style={[styles.font, styles.fontBold]}>Total {total}</Text>
        </View>
      </View>
    )
  }

  return (
    <Document>
      <Page wrap style={styles.body}>
        <View style={styles.spacer}>
          <Text
            style={[
              styles.fontBold, styles.uppercase, { fontSize: 20, textAlign: 'center' }
            ]}
          >Pedidos {date}</Text>
        </View>
        <Table data={data} columns={headers} />
        <Text
          style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )} fixed />
      </Page>
    </Document>
  )
};

/* Font.register(
  {
    family: 'Urbanist-Regular',
    src: Regular
  },
);
Font.register(
  {
    family: 'Urbanist-SemiBold',
    src: SemiBold
  }
);
Font.register(
  {
    family: 'BrittanySignature',
    src: BrittanySignature
  }
); */

