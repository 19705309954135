import { message } from 'antd'
import axios from 'axios';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { api } from '../api/api';
import Input from '../components/Input';
import { AuthContext } from '../context/AuthProvider';
import styles from '../styles/Login.module.scss'

export const Login = () => {

  const { setUser } = useContext(AuthContext)

  const { register, handleSubmit, control, formState: { errors } } = useForm();


  const login = ({ email, password }: any) => {
    api.post(`/api/auth/login`, { email, password }).then(({ data }) => {
      if (data.role.code === 'customer') {
        return message.error('Acceso restringido.')
      }
      localStorage.setItem('token', data.token)
      localStorage.setItem('token-init-date', new Date().getTime().toString())
      setUser(data)
    }).catch((error) => {
      message.error(error.response.data.message)
    })
  }

  return (
    <div className={styles.login}>
      <div className={styles.inner}>
        <div className={styles.logo} style={{ textAlign: 'center', marginBottom: 100 }}>
          <img src="/rosa-toronja-logo.svg" alt="" />
        </div>
        <div className={styles.form}>
          <div className="group">
            <Input
              register={register}
              placeholder='Correo electrónico'
              name='email'
              type='email'
              errors={errors}
              label='Correo eléctronico'
              required
            />
          </div>
          <div className="group">
            <Input
              register={register}
              placeholder='Contraseña'
              name='password'
              type='password'
              errors={errors}
              label='Contraseña'
              required
            />
          </div>
          <div className="group">
            <button onClick={handleSubmit(login)} className='btn btn-black btn-block'>Iniciar sesión</button>
          </div>
        </div>
      </div>
    </div>
  )
}
