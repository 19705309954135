import { Document, Font, Page, Text, View } from "@react-pdf/renderer";
import { styles } from "../helpers/styles";
import { Order } from "../interfaces/Order";

export const Report = ({ data, date }: any) => {

  const headers = [
    {
      title: 'Numero de pedido',
      dataIndex: 'number',
      key: 'number',
    },
    /* {
      title: 'Productos',
      dataIndex: 'products',
      key: 'products',
      render: (_text: string, record: Order) => {

        return record.cart ?
          <View>
            {
              record.cart.products.map(item => (
                <View>
                  <Text
                    style={styles.font}
                  >-</Text>
                  <Text
                    style={styles.font}
                  >{item.name}</Text>
                </View>
              ))
            }
          </View>

          :
          Array.isArray(record.products) &&
          record.products?.map(spec => {
            return (
              <View>
                <Text
                  style={styles.font}
                >-</Text>
                <Text
                  style={styles.font}
                >{spec.name}</Text>
              </View>
            )

          }
          )
      },
      width: 200
    }, */
    {
      title: 'Cliente',
      dataIndex: 'customer',
      key: 'customer',
    },
    {
      title: 'Telefono de cliente',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Direccion',
      dataIndex: 'shippingAddress',
      key: 'shippingAddress',
      render: (_text: string, record: Order) =>
        <Text
        >{record.shippingAddress?.street}, {record.shippingAddress?.colonia}, {record.shippingAddress?.postalCode}, {record.shippingAddress?.city}, {record.shippingAddress?.state}
        </Text>
    },

  ]

  const Table = ({ data, columns }: any) => {
    return (
      <View>
        {
          (data && data.length > 0)
            ? data.map((item: any) => (
              <View
                key={item.id}
                style={{
                  marginBottom: 20
                }}
              >
                {
                  columns.map((header: any) => (
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                      key={header.title}>
                      <Text
                        style={[styles.font, styles.fontBold, styles.uppercase]}
                      >{header.title}: </Text>
                      {
                        ('render' in header)
                          ? <Text
                            style={[styles.font, { width: '70%' }]}
                          >{header.render(item[header.dataIndex], item)}</Text>
                          : <Text
                            style={[styles.font, { width: '70%' }]}
                          >{item[header.dataIndex]}</Text>
                      }
                    </View>
                  ))
                }
              </View>
            )
            )
            : <View></View>
        }
      </View>
    )
  }

  return (
    <Document>
      <Page wrap style={styles.body}>
        <View style={styles.spacer}>
          <Text
            style={[
              styles.fontBold, styles.uppercase, { fontSize: 20, textAlign: 'center' }
            ]}
          >Pedidos {date}</Text>
        </View>
        <Table data={data} columns={headers} />
        <Text
          style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )} fixed />
      </Page>
    </Document>
  )
};

