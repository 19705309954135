import { useContext, useEffect, useState } from 'react'
import { DatePicker, Input, Table, Tag } from 'antd'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Order } from '../../interfaces/Order'
import { Report } from '../../components/Report'
import { VerticalTable } from '../../components/VerticalTable'
import { AuthContext } from '../../context/AuthProvider'
import { api } from '../../api/api'

const Orders = () => {
  const [orders, setOrders] = useState<Order[]>([])
  const [loading, setLoading] = useState(true)
  const [tempOrders, setTempOrders] = useState<Order[]>([])
  const [doc, setDoc] = useState(false)
  const { user } = useContext(AuthContext)
  useEffect(() => {
    api.get(`/api/orders/`,
      {
        headers: {
          'Content-type': 'application/json',
          'x-token': user.token
        }
      }
    ).then(({ data }) => {
      setOrders(data.orders.sort((a: Order, b: Order) => {
        let aNumber = +a.number.substring(3, 6)
        let bNumber = +b.number.substring(3, 6)
        return (
          bNumber - aNumber
        )
      }))
      setTempOrders(data.orders)
      setLoading(false)
    })
      .catch(() => {
        setLoading(false)
      })
  }, [user.token])

  const headers = [
    {
      title: 'Numero de pedido',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Productos',
      dataIndex: 'products',
      key: 'products',
      render: (_text: string, record: Order) => {
        return record.cart ?
          <div className='flex column'>
            {
              record.cart.products.map(item => (
                <div className='flex'>
                  <span>-</span>
                  <span className='ml-10'>{item.name}</span>
                </div>
              ))
            }
          </div>
          :
          record.products?.map(product => {
            return (
              <div className='flex'>
                <span>-</span>
                <span className='ml-10'>{product.name}</span>
              </div>
            )

          }
          )
      },
      width: 200
    },
    {
      title: 'Municipio de entrega',
      dataIndex: 'city',
      key: 'city',
      render: (_text: string, record: Order) => record.shippingAddress?.city,
      /*       filters: cities?.map(city => ({
              text: city,
              value: city,
            })), */
      //onFilter: (value: any, record: Order) => record.shippingAddress?.city?.includes(value),
    },
    {
      title: 'Nombre de cliente',
      dataIndex: 'customer',
      key: 'customer'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => <Tag color={text === 'Nuevo' ? '#2db7f5' : text === 'Enviado' ? '#faad14' : text === 'Entregado' ? '#87d068' : '#d9363e'}>{text}</Tag>,
      filters: [
        {
          text: 'Nuevo',
          value: 'Nuevo',
        },
        {
          text: 'Enviado',
          value: 'Enviado',
        },
        {
          text: 'Entregado',
          value: 'Entregado',
        },
        {
          text: 'Cancelado',
          value: 'Cancelado',
        },
      ],
      onFilter: (value: any, record: Order) => record.status?.includes(value),
    },
    {
      title: 'Total (MXN)',
      dataIndex: 'total',
      key: 'total',
      render: (_text: string, record: Order) => <>$ {record.total}</>
    },
    {
      title: 'Tipo de pedido',
      dataIndex: 'type',
      key: 'type',
      render: (text: string) => <Tag color={text === 'Tienda' ? 'green' : 'blue'}>{text}</Tag>,
      filters: [
        {
          text: 'Manual',
          value: 'Manual',
        },
        {
          text: 'Tienda',
          value: 'Tienda',
        },
      ],
      //   onFilter: (value: any, record: Order) => record.type.includes(value),
    },
    {
      title: 'Detalles',
      dataIndex: 'detalles',
      key: 'detalles',
      render: (_text: string, record: Order) => <Link to={`/pedidos/${record.number}`} className='btn btn-small btn-black'>Ver</Link>
    }
  ]

  const handleSearch = (e: any) => {
    const searchTerm = e.target.value.toLowerCase()
    // setOrders(tempOrders.filter((order) => order.name?.toLowerCase().includes(searchTerm) || order.number?.toLowerCase().includes(searchTerm)))
  }


  const filter = (e: any) => {
    if (e) {
      const start = moment(e[0]).format('ll')
      const end = moment(e[1]).format('ll')
      let filtered = []
      if (start === end) {
        filtered = tempOrders.filter(order => moment(order.createdDate).format('ll') === start || moment(order.createdDate).format('ll') === end)
      } else {
        const start = moment(e[0]).format('YYYY-MM-DD')
        const end = moment(e[1]).format('YYYY-MM-DD')
        filtered = tempOrders.filter(order => moment(moment(order.createdDate).format('YYYY-MM-DD')).isBetween(start, end, undefined, '[]'))
      }
      setOrders(filtered)
    }
  }

  if (loading) return <span>Loading...</span>

  return (
    <div className='overviewPage'>
      <div className="overviewPage__content">
        <div className='flex justify-between align-center mb-20'>
          <h2>Filtrar pedidos por rango o por dia</h2>
          <div className='flex align-center'>
            {
              doc ? <div className="download-btn-wrapper">
                <PDFDownloadLink
                  document={<Report
                    data={orders}

                  />

                  }
                  fileName={`Hoja de rutas`}>
                  {({ loading }) => (loading ?
                    <button className='btn' disabled>Generando reporte...</button> :
                    <button
                      className='btn btn-black'
                      onClick={() => { setDoc(false) }}
                    >Descargar</button>)}
                </PDFDownloadLink>
              </div> :
                <div style={{ display: 'flex' }}>
                  <button
                    className='btn btn-black'

                    onClick={() => { setDoc(true) }}
                  >
                    Generar reporte
                  </button>
                </div>
            }
            <Link style={{ marginLeft: 10 }} className='btn btn-black' to='/pedidos/crear-pedido'>Crear pedido</Link>
          </div>

        </div>
        <div className='mb-20'>
          <DatePicker.RangePicker onChange={filter} format='DD-MMM-YYYY' />
        </div>
        <div className='mb-20'>
          <Input onChange={handleSearch} placeholder='Buscar pedidos por numero de pedido o nombre de cliente' />
        </div>
        <div className="mb-20">
          <h3>Mostrando {orders.length} pedidos</h3>
        </div>
        <div className='mobile-table'>
          <VerticalTable data={orders} headers={headers} />
        </div>
        <div className='web-table'>
          <Table dataSource={orders} size='small' columns={headers} />
        </div>
      </div>
    </div>
  )
}

export default Orders
