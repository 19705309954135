import React, { useContext } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { ShoppingOutlined, ShoppingCartOutlined, TeamOutlined, TagsOutlined, SettingOutlined, FileTextOutlined } from '@ant-design/icons'
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd'
import { AuthContext } from '../context/AuthProvider';
import { User } from '../interfaces/User';
import styles from '../styles/Sidebar.module.scss'

export const Sidebar = () => {

  const location = useLocation()

  const { user, setUser } = useContext(AuthContext)

  const logout = () => {
    localStorage.removeItem('token')
    setUser({} as User)
  }

  const menu = (
    <Menu>
      <Menu.Item
        onClick={logout}
        key="0">
        <span>Cerrar sesión</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className={styles.topBar}>
        <Dropdown overlay={menu} trigger={['click']}>
          <div>
            <span style={{ marginRight: 7 }}>
              {user.name}
            </span>
            <DownOutlined />
          </div>
        </Dropdown>
      </div>
      <div className={styles.sidebar}>
        <div className={styles.logo}>
          <div>
            <img src='/rosa-toronja-logo.svg' alt='' />
          </div>
        </div>
        <NavLink activeClassName={styles.active} to='/pedidos'>
          <div className={styles.link}>
            <ShoppingOutlined />
            <span className={styles.linkName}>Pedidos</span>
          </div>
        </NavLink>
        <div className={styles.subMenuWrapper}>
          <NavLink activeClassName={styles.active} to='/productos/'>
            <div className={styles.link}>
              <ShoppingCartOutlined />
              <span className={styles.linkName}>Productos</span>
            </div>
          </NavLink>
          {
            location.pathname.includes('/productos') &&
            <div className={styles.subMenu}>
              <NavLink activeClassName={styles.active} to='/productos/todos'>
                <div className={styles.link}>

                  <span className={styles.linkName}>Todos los productos</span>
                </div>
              </NavLink>
              <NavLink activeClassName={styles.active} to='/productos/categorias'>
                <div className={styles.link}>

                  <span className={styles.linkName}>Categorias</span>
                </div>
              </NavLink>
            </div>
          }
        </div>
        <NavLink activeClassName={styles.active} to='/clientes'>
          <div className={styles.link}>
            <TeamOutlined />
            <span className={styles.linkName}>Clientes</span>
          </div>
        </NavLink>
        <NavLink activeClassName={styles.active} to='/descuentos'>
          <div className={styles.link}>
            <TagsOutlined />
            <span className={styles.linkName}>Descuentos</span>
          </div>
        </NavLink>
        {
          user.role.code === 'admin' &&
          <div className={styles.subMenuWrapper}>
            <NavLink activeClassName={styles.active} to='/reportes/'>
              <div className={styles.link}>
                <FileTextOutlined />
                <span className={styles.linkName}>Reportes</span>
              </div>
            </NavLink>
            {
              location.pathname.includes('/reportes') &&
              <div className={styles.subMenu}>
                <NavLink activeClassName={styles.active} to='/reportes/rutas'>
                  <div className={styles.link}>
                    <span className={styles.linkName}>Rutas</span>
                  </div>
                </NavLink>
                <NavLink activeClassName={styles.active} to='/reportes/repartidores'>
                  <div className={styles.link}>
                    <span className={styles.linkName}>Repartidores</span>
                  </div>
                </NavLink>
                <NavLink activeClassName={styles.active} to='/reportes/ventas'>
                  <div className={styles.link}>
                    <span className={styles.linkName}>Ventas</span>
                  </div>
                </NavLink>
              </div>
            }
          </div>
        }
        <NavLink activeClassName={styles.active} to='/general'>
          <div className={styles.link}>
            <SettingOutlined />
            <span className={styles.linkName}>General</span>
          </div>
        </NavLink>
      </div>
    </>
  )
}
