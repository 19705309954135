import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

export const Image = ({ src, style }) => {
  return (
    <>
      <LazyLoadImage
        effect='blur'
        placeholderSrc='/assets/no-image.png'
        style={style}
        src={src}
      />
    </>
  )
}
