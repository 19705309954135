/* eslint-disable react/jsx-indent */
import { Button, message } from 'antd'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Upload } from '../../components/Upload'
import { api } from '../../api/api'
import Input from '../../components/Input';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { Category } from '../../interfaces/Category';
import TextArea from '../../components/TextArea';

export const AddProduct = () => {
  const [images, setImages] = useState<string[]>([])
  const [specList, setSpecList] = useState<string[]>([])
  const [saving, setSaving] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [categories, setCategories] = useState<Category[]>([])
  const history = useHistory()
  const [currentEditing, setcurrentEditing] = useState<number | null>(null);

  const [editing, setEditing] = useState('');
  const [specValue, setSpecValue] = useState('');
  const { register, handleSubmit, control, formState: { errors } } = useForm();

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await api.get('/api/categories')
        setCategories(data.categories)
      } catch (error) {
      }
    }
    fetchData();
  }, []); // Or [] if effect doesn't need props or state

  const onSubmit = async (data: any) => {
    if (images.length === 0) return message.error('Elige al menos 1 imagen')
    try {
      const product = {
        ...data,
        category: data.category?.map((category: any) => category.value),
        images,
        specs: specList
      }
      await api.post('/api/products', product)
      message.success('Producto agregado')
      history.replace('/productos/todos')
    } catch (error: any) {
      if (error) {
        console.log(error);
       // message.error(error.response.data.message)
      }
    }
  }

  return (
    <div className='detailPage'>
      <div className="flex align-center justify-between mb-20">
        <div className="flex">
          <Button onClick={() => { history.goBack() }} className='mr-10' shape="circle" icon={<ArrowLeftOutlined />} />
          <h3>Agregar producto</h3>
        </div>

        <div className="flex">
          <button disabled={saving || uploading} onClick={handleSubmit(onSubmit)} className='btn btn-black'>{saving ? 'Guardando...' : uploading ? 'Subiendo imagenes...' : 'Guardar'}</button>
        </div>

      </div>
      <div className='card'>
        <div className="group">
          <Input
            register={register}
            placeholder='Nombre'
            name='name'
            errors={errors}
            label='Nombre del producto'
            required
          />
        </div>
        <div className="group">
          <TextArea
            register={register}

            placeholder='Descripción'
            name='description'
            errors={errors}
            label='Descripción del producto'
            required
          />
        </div>
        <div className="group">
          <Input

            register={register}
            placeholder='Precio'
            name='price'
            errors={errors}
            label='Precio'
            required
          />
        </div>
        <div className="group">
          <Input
            register={register}

            placeholder='Tipo de producto'
            name='type'
            errors={errors}
            label='Tipo de producto'
            required
          />
        </div>
        <div className="group">
          <Input
            register={register}
            placeholder='Medidas'

            name='size'
            errors={errors}
            label='Medidas'
          />
        </div>
        <div className="group">
          <Input
            register={register}

            placeholder='Material'
            name='material'
            errors={errors}
            label='Material'
          />
        </div>
        <div className="group">
          <TextArea
            register={register}

            placeholder='Como usar'
            name='howTo'
            errors={errors}
            label='Como usar'
          />
        </div>
        <div className="group">
          <TextArea
            register={register}

            placeholder='Mantenimiento'
            name='maintenance'
            errors={errors}
            label='Mantenimiento'
          />
        </div>
        <div className="group">
          <span>Categoría(s)</span>
          <Controller
            name="category"
            control={control}
            render={({ field: { onChange, onBlur, value } }) =>
              <Select
                isMulti
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                placeholder='Categoría(s)'
                isSearchable={true}
                options={categories.map(item => ({
                  value: item.id,
                  label: item.name
                }))}
              />}
          />
          {errors.category && <span className='error'>Campo requerido</span>}
        </div>
        <div className="group">
          <span>Iconos</span>
          <Controller
            name="icons"

            control={control}
            render={({ field: { onChange, onBlur, value } }) =>
              <Select
                isMulti
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                placeholder='Iconos'
                isSearchable={true}
                options={[
                  {
                    label: 'Anal',
                    value: 'anal'
                  },
                  {
                    label: 'A prueba de agua',
                    value: 'prueba-de-agua'
                  },
                  {
                    label: 'Recargable',
                    value: 'recargable'
                  },
                  {
                    label: 'Silencioso',
                    value: 'silencioso'
                  },
                  {
                    label: 'Tacto suave',
                    value: 'tacto-suave'
                  },
                  {
                    label: 'Vibrador',
                    value: 'vibrador'
                  },
                ]}
              />}
          />
        </div>
        <div className="specs group">
          <label htmlFor="">Especificaciones</label>
          <div>
            <input
              value={specValue}
              placeholder='Escribe aqui y da click en +'
              onChange={(e) => {
                setSpecValue(e.target.value)
              }}
              type="text" className="input" />
            <div
              onClick={() => {
                if(specValue==='') return
                setSpecList([...specList, specValue])
                setSpecValue('')
              }}
              className='icon'>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
              </svg>
            </div>
          </div>
          <div className="specList">
            <h4>Especificaciones añadidas</h4>
            {
              specList.map((item, index) => (
                <div key={item}>
                  <div className="left">
                    {
                      index + 1 === currentEditing ?
                        <input
                          defaultValue={item}
                          onChange={(e) => {
                            setEditing(e.target.value)
                          }}
                          className='input'
                          type="text"
                        /> : <span
                        >{item}
                        </span>
                    }
                  </div>
                  <div className="right">
                    {
                      !currentEditing &&
                      <svg
                        onClick={() => {
                          setcurrentEditing(index + 1)
                        }}
                        xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                      </svg>
                    }
                    {
                      currentEditing === index + 1 &&
                      <svg
                        onClick={() => {
                          setcurrentEditing(null)
                          const specs = [...specList]
                          specs[currentEditing-1] = editing
                          setSpecList(specs)
                        }}
                        xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    }
                    <svg
                      onClick={() => {
                        setSpecList(specList.filter(spec => spec !== item))
                      }}
                      xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
        <div className="group">
          <input {...register('hasDiscount')} type="checkbox" name="hasDiscount" id="hasDiscount" />
          <label htmlFor="hasDiscount">Tiene descuento</label>
        </div>
        <div className="group">
          <input {...register('published')} type="checkbox" name="published" id="published" />
          <label htmlFor="published">Publicar</label>
        </div>
        <Upload
          url={`/api/products/upload`}
          images={images}
          setImages={setImages}
          uploading={uploading}
          setUploading={setUploading}
        />
      </div>
    </div>
  )
}
