import { Calendar } from '../components/Calendar'
import { LinksLayout } from '../components/LinksLayout'

const SalesReport = () => {

  const links = [
    {
      to: '/reportes/rutas',
      name: 'Rutas'
    },
    {
      to: '/reportes/repartidores',
      name: 'Repartidores'
    },
    {
      to: '/reportes/ventas',
      name: 'Ventas'
    },
  ]

  return (
    <LinksLayout links={links} title='Reporte de ventas'>
      <Calendar />
    </LinksLayout>
  )
}

export default SalesReport
