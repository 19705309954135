import { Button, message } from 'antd'
import { useContext, useEffect, useRef, useState } from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router'
import { Controller, useForm } from 'react-hook-form'
import imageCompression from 'browser-image-compression';
import { AuthContext } from '../context/AuthProvider'
import { api } from '../api/api'
import Input from '../components/Input'
import Select from 'react-select';
import { Product } from '../interfaces/Product'
import { Category } from '../interfaces/Category'


export const AddSlide = () => {
  const imageRef = useRef<any>()
  const [image, setImage] = useState('')
  const [uploading, setUploading] = useState(false)
  const [saving, setSaving] = useState(false)
  const history = useHistory()
  const { user } = useContext(AuthContext)
  const { register, handleSubmit, control, formState: { errors } } = useForm();
  const [type, setType] = useState('')

  const [products, setProducts] = useState<Product[]>([])
  const [categories, setCategories] = useState<Category[]>([])

  const handleFileUpload = async (e: any) => {
    const file = e.target.files[0]
    if (!file) {
      return
    }
    setUploading(true)
    const options = {
      maxSizeMB: 6,
      maxWidthOrHeight: 3000,
      useWebWorker: true,
    }
    let compressedBlob
    let compressedFile

    const formData = new FormData()
    try {
      compressedBlob = await imageCompression(file, options);
      compressedFile = new File([compressedBlob], compressedBlob.name)
    } catch (error) {
    }

    formData.append('image', compressedFile as any)

    try {
      const { data } = await api.post('/api/slides/upload', formData,
        {
          headers: {
            'Content-type': 'application/json',
            'x-token': user.token
          }
        }
      )
      message.success('Imagen cargada')
      setUploading(false)
      setImage(data.Location)
    } catch (error: any) {
      message.error(error.response.data.message)
      setUploading(false)
    }
  }

  const onSubmit = async (data: any) => {
    if (image === '') return message.error('Elige una imagen para la slide')
    const slide = {
      ...data,
      image,
      type: data.type?.value,
      ctaRedirect: data.type?.value === 'product' ? `/shop/${data.product?.value}` : `/categories/${data.category?.value}`
    }
    try {
      await api.post('/api/slides', slide)
      message.success('Slide creada')
    } catch (error: any) {
      message.error(error.response.data.message)
    }

  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: categoriesData } = await api.get('/api/categories')
        const { data: productsData } = await api.get('/api/products')

        setCategories(categoriesData.categories)
        setProducts(productsData.products)

      } catch (error) {

      }
    }
    fetchData()
  }, [])

  return (
    <div className='detailPage'>

      <div className="flex align-center justify-between mb-20">
        <div className="flex">
          <Button onClick={() => { history.goBack() }} className='mr-10' shape="circle" icon={<ArrowLeftOutlined />} />
          <h3>Agregar slide</h3>
        </div>

        <div className="flex">
          <button disabled={saving || uploading} onClick={handleSubmit(onSubmit)} className='btn btn-black'>{saving ? 'Guardando...' : uploading ? 'Subiendo imagenes...' : 'Guardar'}</button>
        </div>

      </div>

      <div className='form__container'>
        <div className='group'>
          <span>Imagen de slide</span>
          <input
            onChange={handleFileUpload}
            ref={imageRef}
            style={{ display: 'none' }}
            type='file'
          />
          <div>
            {
              image !== '' &&
              <button
                type='button'
                className='imagePreview__img'
                style={{
                  marginBottom: 30
                }}
              >
                <img style={{ width: '100%', display: 'block' }} src={image} alt='' />
              </button>
            }
            <div onClick={() => { imageRef.current.click() }} style={
              {
                width: '100%',
                height: 100,
                border: '2px dashed #cdcdcd',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                fontSize: 14,
                cursor: 'pointer',
              }
            }>
              <span>
                {image !== '' ? 'Cambiar' : 'Elegir'} imagen
              </span>
            </div>
          </div>
        </div>
        <div className="group">
          <Input
            register={register}
            placeholder='Titulo'
            name='title'
            errors={errors}
            label='Titulo'
          />
        </div>
        <div className="group">
          <Input
            register={register}
            placeholder='Subtitulo'
            name='subtitle'
            errors={errors}
            label='Subtitulo'
          />
        </div>
        <div className="group">
          <Input
            required
            type='number'
            register={register}
            placeholder='Posición (numero de slide)'
            name='position'
            errors={errors}
            label='Posición (numero de slide)'
          />
        </div>
        <div className="group">
          <Input
            register={register}
            label='Texto de accion principal'
            name='ctaText'
            errors={errors}
            placeholder='"Explorar", "Comprar", etc'
          />
        </div>
        <div className="group">
          <span>Acción principal redirige hacia:</span>
          <Controller
            name="type"
            defaultValue={[]}
            control={control}
            render={({ field: { onChange, onBlur, value } }) =>
              <Select
                onChange={(e) => {
                  onChange(e)
                  setType(e.value)
                }}
                onBlur={onBlur}
                value={value}
                placeholder='Elegir'
                options={[
                  {
                    label: 'Un producto',
                    value: 'product'
                  },
                  {
                    label: 'Una categoría',
                    value: 'category'
                  },
                ]}
              />}
          />
        </div>
        {
          type === 'product' &&
          <div className="group">
            <span>Elige producto a redirigir en accion principal:</span>
            <Controller
              name="product"
              defaultValue={[]}
              control={control}
              render={({ field: { onChange, onBlur, value } }) =>
                <Select
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  placeholder='Elegir'
                  options={products.map(item => (
                    {
                      label: item.name,
                      value: item.code
                    }
                  ))}
                />}
            />
          </div>
        }
        {
          type === 'category' &&
          <div className="group">
            <span>Elige categoría a redirigir en accion principal:</span>
            <Controller
              name="category"
              defaultValue={[]}
              control={control}
              render={({ field: { onChange, onBlur, value } }) =>
                <Select
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  placeholder='Elegir'
                  options={categories.map(item => (
                    {
                      label: item.name,
                      value: item.code
                    }
                  ))}
                />}
            />
          </div>
        }
      </div>
    </div >
  )
}
