import { Button, message } from 'antd'
import { useContext, useRef, useState } from 'react'
import { MinusCircleTwoTone, ArrowLeftOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router'
import { AuthContext } from '../../../context/AuthProvider'
import { useForm } from 'react-hook-form'
import Input from '../../../components/Input'
import imageCompression from 'browser-image-compression';
import { api } from '../../../api/api'


export const AddCategory = () => {
  const imageRef = useRef<any>()
  const [image, setImage] = useState('')
  const [uploading, setUploading] = useState(false)
  const [saving, setSaving] = useState(false)
  const history = useHistory()
  const { user } = useContext(AuthContext)
  const { register, handleSubmit, formState: { errors } } = useForm();
  
  const handleFileUpload = async (e: any) => {
    const file = e.target.files[0]
    if (!file) {
      return
    }
    setUploading(true)
    const options = {
      maxSizeMB: 6,
      maxWidthOrHeight: 3000,
      useWebWorker: true,
    }
    let compressedBlob
    let compressedFile

    const formData = new FormData()
    try {
      compressedBlob = await imageCompression(file, options);
      compressedFile = new File([compressedBlob], compressedBlob.name)
    } catch (error) {
    }

    formData.append('picture', compressedFile as any)

    try {
      const { data } = await api.post('/api/categories/upload', formData,
        {
          headers: {
            'Content-type': 'application/json',
            'x-token': user.token
          }
        }
      )
      message.success('Imagen cargada')
      setUploading(false)
      setImage(data.Location)
    } catch (error: any) {
      message.error(error.response.data.message)
      setUploading(false)
    }
  }

  const onSubmit = async (data: any) => {
    if (image === '') return message.error('Elige una imagen para la categoria')
    try {
      const category = {
        ...data,
        picture: image
      }
      await api.post('/api/categories', category)
      message.success('Categoria creada')
      history.replace('/productos/categorias')
    } catch (error: any) {
      message.error(error.response.data.message)
    }

  }

  return (
    <div className='detailPage'>

      <div className="flex align-center justify-between mb-20">
        <div className="flex">
          <Button onClick={() => { history.goBack() }} className='mr-10' shape="circle" icon={<ArrowLeftOutlined />} />
          <h3>Agregar categoria</h3>
        </div>

        <div className="flex">
          <button disabled={saving || uploading} onClick={handleSubmit(onSubmit)} className='btn btn-black'>{saving ? 'Guardando...' : uploading ? 'Subiendo imagenes...' : 'Guardar'}</button>
        </div>

      </div>

      <div className='form__container'>
        <div className="group">
          <Input
            register={register}
            placeholder='Nombre'
            name='name'
            errors={errors}
            label='Nombre de categoria'
            required
          />
        </div>
        <div className="group">
          <Input
            register={register}
            placeholder='Description'
            name='description'
            errors={errors}
            label='Descripcion de categoría'
            required
          />
        </div>
        <div>
          <input
            onChange={handleFileUpload}
            ref={imageRef}
            style={{ display: 'none' }}
            type='file'
          />
          <div style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>
            {
              image !== '' && <button
                type='button' className='imagePreview__img'>
                <img style={{ width: 150 }} src={image} alt='' />
                <Button
                  htmlType='button'
                  shape='circle'
                  icon={<MinusCircleTwoTone twoToneColor='red' />}
                  type='link'
                  onClick={() => {
                    setImage('')
                  }}
                />
              </button>
            }
            <div onClick={() => { imageRef.current.click() }} style={
              {
                width: 150,
                height: 150,
                border: '2px dashed #cdcdcd',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                fontSize: 14,
                cursor: 'pointer',
                marginLeft: image ? 20 : 0
              }
            }>
              <span>
                {image !== '' ? 'Cambiar' : 'Elegir'} imagen
              </span>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}
