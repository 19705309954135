import { Redirect } from 'react-router-dom';
import { Account } from '../pages/Account';
import { Customers } from '../pages/Customers';
import { AddDiscount } from '../pages/discounts/AddDiscount';
import { Discount } from '../pages/discounts/Discount';
import { Discounts } from '../pages/discounts/Discounts';
import { Slides } from '../pages/General';
import { CreateOrder } from '../pages/orders/CreateOrder';
import { Order } from '../pages/orders/Order';
import Orders from '../pages/orders/Orders';
import { AddProduct } from '../pages/products/AddProduct';
import { AllProducts } from '../pages/products/AllProducts'
import { AddCategory } from '../pages/products/categories/AddCategory';
import { Categories } from '../pages/products/categories/Categories';
import { Category } from '../pages/products/categories/Category';
import { Product } from '../pages/products/Product';
import DeliveryReport from '../pages/DeliveryReport';
import RoutesReport from '../pages/RoutesReport';
import SalesReport from '../pages/SalesReport';
import { AddSlide } from '../pages/AddSlide';
import { Slide } from '../pages/Slide';

const routes = [
  {
    path: 'pedidos',
    component: Orders,
    exact: true
  },
  {
    path: 'pedidos/crear-pedido',
    component: CreateOrder,
    exact: true
  },
  {
    path: 'pedidos/:number',
    component: Order,
    exact: true
  },
  {
    path: 'clientes',
    component: Customers,
    exact: true
  },
  {
    path: 'clientes/:id',
    component: Account,
    exact: true
  },
  {
    path: 'descuentos',
    component: Discounts,
    exact: true
  },
  {
    path: 'descuentos/agregar-descuento',
    component: AddDiscount,
    exact: true
  },
  {
    path: 'descuentos/:discountId',
    component: Discount,
    exact: true
  },
  {
    path: 'general',
    component: Slides,
    exact: true
  },
  {
    path: 'productos',
    component: () => <Redirect to='/productos/todos' />,
    exact: true
  },
  {
    path: 'productos/todos',
    component: AllProducts,
    exact: true
  },
  {
    path: 'productos/agregar-producto',
    component: AddProduct,
    exact: true
  },
  {
    path: 'productos/categorias',
    component: Categories,
    exact: true
  },
  {
    path: 'productos/categorias/agregar-categoria',
    component: AddCategory,
    exact: true
  },
  {
    path: 'productos/categorias/:categoryId',
    component: Category,
    exact: true
  },
  {
    path: 'productos/:productId',
    component: Product,
    exact: true
  },
  {
    path: 'reportes/',
    component: () => <Redirect to='/reportes/rutas' />,
    exact: true
  },
  {
    path: 'reportes/repartidores',
    component: DeliveryReport,
    exact: true
  },
  {
    path: 'reportes/rutas',
    component: RoutesReport,
    exact: true
  },
  {
    path: 'reportes/ventas',
    component: SalesReport,
    exact: true
  },
  {
    path: 'general/agregar-slide',
    component: AddSlide,
    exact: true
  },
  {
    path: 'general/slides/:id',
    component: Slide,
    exact: true
  }
];

export default routes;